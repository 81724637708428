import React, {Component} from 'react';
import Modal from "react-bootstrap/Modal";
import Backdrop from "@material-ui/core/Backdrop/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import {PostCompnentWebx} from "./PostCompnent";

class LogoutMessage extends Component {
    constructor(props) {
        super(props);


        this.showModal = this.showModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.signOut = this.signOut.bind(this);
        this.signOut = this.signOut.bind(this);
        this.handleToggleProgress = this.handleToggleProgress.bind(this);
        this.handleCloseProgress = this.handleCloseProgress.bind(this);

        this.state = {
            openModalLogout: false,
            setOpenModalLogout: false,
            setOpenProgress: false,
            openprogress: false,
        }
    }

    signOut() {
        this.handleToggleProgress();

        let formData = new FormData();    //formdata object

        formData.append('merchant_id', sessionStorage.getItem("merchant_id") + '|' + sessionStorage.getItem("encrypted_merchant_id"));   //append the values with key, value pair

        PostCompnentWebx("logoutRequest", formData).then(response => {
            sessionStorage.removeItem('userToken');
            localStorage.removeItem('userToken');
            sessionStorage.removeItem('laravel_token');
            localStorage.removeItem('laravel_token');
            localStorage.removeItem('merchant_id');
            sessionStorage.removeItem('merchant_id');
            sessionStorage.removeItem('doing_business_name');
            localStorage.removeItem("collapsed");
            localStorage.removeItem("doing_business_name");
            sessionStorage.removeItem("doing_business_name");
            sessionStorage.removeItem("encrypted_merchant_id");
            sessionStorage.removeItem("logo_image");
            sessionStorage.removeItem("merchant_number");
            sessionStorage.removeItem("user_name");
            sessionStorage.removeItem("permissions");
            sessionStorage.removeItem("admin_status");
            sessionStorage.removeItem('bpsp_status');
            sessionStorage.removeItem("merchant_view")

            sessionStorage.removeItem("password_change")
            sessionStorage.removeItem("new_password")
            sessionStorage.removeItem("bpsp_mdr_rate")
            sessionStorage.removeItem("total_due_usd")
            sessionStorage.removeItem("total_due_lkr")
            sessionStorage.removeItem("user_email")
            sessionStorage.removeItem("qr_enable")

            sessionStorage.removeItem("admin_status");

            sessionStorage.removeItem("sms_enabled");
            sessionStorage.removeItem("emi_enabled");


            this.handleCloseProgress()
            window.location.reload();

        });


    }

    showModal() {
        this.setState({
            openModalLogout: true,
            setOpenModalLogout: true
        });
    }

    handleCloseProgress = () => {
        this.setState({setOpenProgress: false});
        this.setState({openprogress: false});
    };
    handleToggleProgress = () => {
        this.setState({setOpenProgress: true});
        this.setState({openprogress: true});
    };

    closeModal() {
        this.setState({
            openModalLogout: false,
            setOpenModalLogout: false
        });
    }

    render() {
        return (
            <div>
                <Backdrop className="backdrop-loading" open={this.state.openprogress}>
                    <CircularProgress color="inherit"/>
                </Backdrop>
                <li data-toggle="modal" data-target="#create-invoice-modal" className="l-item" onClick={this.showModal}>
                    <div className="icn"><i className="fas fa-sign-out-alt"></i></div>
                    <p className="tp">Log Out</p>
                    <p className="bp">Sign out of account</p>
                </li>
                <Modal
                    show={this.state.openModalLogout}

                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    id="info-modal"
                    className="info-modal-class"
                >
                    <Modal.Body className="modal-type-2">
                        {/*  <button onClick={this.closeModal} className="cls-btn">X</button>*/}
                        <div className="row log-sec">
                            <div className="col-12 suc-outer">

                                <h4 className="in-h mb-4">Are you sure?</h4>
                            </div>
                            <div className="col-6 submit">
                                <div id="submit-btn" className="btn btn-primary" onClick={this.signOut}><i
                                    className="fas fa-check"></i> Yes
                                </div>
                            </div>
                            <div className="col-6 submit">
                                <div id="submit-btn" className="btn btn-primary nbtn"
                                     onClick={this.closeModal}><i className="fas fa-times"></i> No
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal isOpen={this.state.openModalLogout} className="ref-modal">

                </Modal>
            </div>
        );
    }
}

export default LogoutMessage;