import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {myConfig} from "../../config";
import axios from "axios";
import IdleTimer from 'react-idle-timer';
import NumberFormat from "react-number-format";
import Backdrop from "@material-ui/core/Backdrop/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import LinearDeterminate from "../ProgressBar";
import {GetWebxLaravel, PostCompnentWebx} from "../PostCompnent";
import LogoutMessage from "../LogoutMessage";
import {decode} from 'html-entities';
export class NavigationSandbox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lkr_transaction_total:0,
            total_due:0,
            total_due_usd:0,
            doing_business_name:'',
            logo_file:sessionStorage.getItem("logo_image"),
//idle timer
            timeout:1000 * 600 * 1,
            showModal: false,
            userLoggedIn: false,
            isTimedOut: false
        };
        this.handleCloseProgress = this.handleCloseProgress.bind(this);
        this.handleToggleProgress = this.handleToggleProgress.bind(this);
        this.loadTotalDue = this.loadTotalDue.bind(this);
        this.getUser = this.getUser.bind(this);
        this.showUserMenu = this.showUserMenu.bind(this);
        this.closePop = this.closePop.bind(this);

        this.idleTimer = null
        this.onAction = this.onAction.bind(this)
        this.onActive = this.onActive.bind(this)
        this.onIdle = this.onIdle.bind(this)
        this.getCustomerData = this.getCustomerData.bind(this)
        this.getImage = this.getImage.bind(this)
    }
    getImage(laravel_token){
        this.handleToggleProgress();

        let BaseUrl = myConfig.laravelUrl;
        let lar_config = {
            headers: {
                'content-type': 'multipart/form-data',
                'Authorization': sessionStorage.getItem("laravel_token"),

            },
        };

        return axios.get(BaseUrl + "merchants/get_image/"+sessionStorage.getItem("merchant_id")+'|'+sessionStorage.getItem("encrypted_merchant_id"),lar_config)
            .then(response => {
                this.handleCloseProgress();
                this.setState({
                    logo_file:response.data!==null?response.data:sessionStorage.getItem("logo_image"),
                });
            })
            .catch(error => {
       /*         if (error.response){
                    if (error.response.status==401){
                        sessionStorage.removeItem('userToken');
                        localStorage.removeItem('userToken');
                        sessionStorage.removeItem('laravel_token');
                        localStorage.removeItem('laravel_token');
                        localStorage.removeItem('merchant_id');
                        sessionStorage.removeItem('merchant_id');
                        window.location.reload();
                    }
                }*/

            });

    }
    getCustomerData(userToken){
        let BaseUrl = myConfig.apiUrl;
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                'Authorization':'Bearer'+' '+userToken
            }
        };
        let formData=new FormData();
        PostCompnentWebx("getStoreData",formData).then(response=>{
            sessionStorage.setItem("doing_business_name",response.data[0].doing_business_name);
            sessionStorage.setItem("merchant_number",response.data[0].merchant_number);

            this.setState({
                doing_business_name:decode(response.data[0].doing_business_name)
            })
        });

    }
    onAction(e) {
        this.setState({isTimedOut: false})
    }

    onActive(e) {
        this.setState({isTimedOut: false})
    }

    onIdle(e) {
        const isTimedOut = this.state.isTimedOut
        if (isTimedOut) {
            sessionStorage.removeItem('userToken');
            localStorage.removeItem('userToken');
            sessionStorage.removeItem('laravel_token');
            localStorage.removeItem('laravel_token');
            localStorage.removeItem('merchant_id');
            sessionStorage.removeItem('merchant_id');
            sessionStorage.removeItem('doing_business_name');
            localStorage.removeItem("collapsed");
            localStorage.removeItem("doing_business_name");
            sessionStorage.removeItem("doing_business_name");
            sessionStorage.removeItem("encrypted_merchant_id");
            sessionStorage.removeItem("logo_image");
            sessionStorage.removeItem("user_name");
            sessionStorage.removeItem("emi_enabled");
            sessionStorage.removeItem("admin_status");
            sessionStorage.removeItem("user_name");
            sessionStorage.removeItem("sms_enabled");
            sessionStorage.removeItem("emi_enabled");

            window.location.reload();
        } else {
            this.setState({showModal: true})
            this.idleTimer.reset();
            this.setState({isTimedOut: true})
        }

    }

    closePop(){
        var dotButton = document.getElementById("dot-button");
        var userMenu = document.getElementById("user-menu");
        userMenu.classList.remove("active");
        dotButton.classList.remove("active");
    }
    showUserMenu(){
        var dotButton = document.getElementById("dot-button");
        var userMenu = document.getElementById("user-menu");
        if (userMenu.classList.contains('active')) {
            userMenu.classList.remove("active");
            dotButton.classList.remove("active");
        } else {
            userMenu.classList.add("active");
            dotButton.classList.add("active");
        }
    }
    getUser(){
        let formData=new FormData();
        this.setState({
            doing_business_name:sessionStorage.getItem("doing_business_name")
        })
    }
    handleCloseProgress = () => {
        this.setState({setOpenProgress: false});
        this.setState({openprogress: false});
    };
    handleToggleProgress = () => {
        this.setState({setOpenProgress: true});
        this.setState({openprogress: true});
    };
    loadTotalDue() {
        this.handleToggleProgress();

        let BaseUrl = myConfig.apiUrl;
        let formData = new FormData();    //formdata objec

        PostCompnentWebx( "getMerchantDue",formData).then(response=>{
            this.handleCloseProgress();
            if (response.data.lkr_total !== undefined) {
                this.setState({total_due: response.data.lkr_total});
            }
            if (response.data.usd_total !== undefined) {
                this.setState({total_due_usd: response.data.usd_total});
            }
        });
    }

    componentWillMount() {

        if (sessionStorage.getItem("userToken")||localStorage.getItem("userToken")) {

            this.getUser();

            //
            var userToken = sessionStorage.getItem("userToken")||localStorage.getItem("userToken");
            this.getImage(sessionStorage.getItem("laravel_token"));
            this.setState({userToken: userToken});
            this.loadTotalDue();
            if (sessionStorage.getItem("doing_business_name")==null){

                this.getCustomerData(sessionStorage.getItem("userToken"));
            }else if (typeof sessionStorage.getItem("doing_business_name")=="undefined"){
                this.getCustomerData(sessionStorage.getItem("userToken"));
            }
        } else {
            this.setState({redirect: true});
        }
        if (sessionStorage.getItem("laravel_token")||localStorage.getItem("laravel_token")) {

            this.getImage(sessionStorage.getItem("laravel_token"));
        }
    }
    render(){
        let lkr_total=this.state.total_due;
        let usd_total=this.state.total_due_usd;

        return (
            <>
                <IdleTimer
                    ref={ref => { this.idleTimer = ref }}
                    element={document}
                    onActive={this.onActive}
                    onIdle={this.onIdle}
                    onAction={this.onAction}
                    debounce={250}
                    timeout={this.state.timeout} />
                <div id="side-navigation" className="col">
                    <div id="user-menu">
                        <div className="pm-in">
                            <ul>
                                {/*  <li data-toggle="modal" data-target="#create-invoice-modal" className="l-item">
                                <div className="icn"><i className="fas fa-sign-out-alt"></i></div>
                                <p className="tp">Log Out of System </p>
                                <p className="bp">Sign Out of this Session</p>
                            </li>*/}
                                <li className="l-item">
                                    <Link to="../settings-general" onClick={this.closePop}>
                                        <div className="icn"><i className="fas fa-user-cog"></i></div>
                                        <p className="tp" >View & Edit Profile </p>
                                        <p className="bp">View your User Profile & Make Changes</p>
                                    </Link>
                                </li>
                                <LogoutMessage />


                            </ul>
                        </div>
                    </div>

                    <Backdrop className="backdrop-loading" open={this.state.openprogress}>
                        <CircularProgress color="inherit" />
                    </Backdrop>

                    <row className="row row_clr sn-row">
                        <div className="col-12 logo-outer">
                            <img src={require('../../images/webxpay-dashboard-logo.svg')} alt="logo" className="logo" />
                        </div>
                        <div className="col-12 act-acc">
                            <h3>Total Due Amount{/* <img src={require('../../images/arrow-black.svg')}  alt="arrow" className="arrow-r" />*/}
                            </h3>
                            <h2 className="t-amt"><NumberFormat
                                value={(Math.round(lkr_total * 100) / 100).toFixed(2)}
                                displayType={'text'} thousandSeparator={true} prefix={'LKR '}/></h2>
                            {usd_total!==0?(
                                <h2 className="t-amt"><NumberFormat
                                    value={(Math.round(usd_total * 100) / 100).toFixed(2)}
                                    displayType={'text'} thousandSeparator={true} prefix={'USD '}/></h2>
                            ):('')
                            }

                        </div>
                        {/* <div className="col-12 act-acc">
                            <h3>Activate Your Account <img src={require('../../images/arrow-black.svg')} alt="arrow" className="arrow-r" />
                            </h3>
                            <p className="ns">Next Step : <span>Choose Subscription Plan</span></p>
                            <div className="row row_clr line-outer">
                                <div className="col-12 line-comp">
                                    <div className="gl"></div>
                                </div>
                                <span>15% Completed</span>
                                <span>18 Days Left</span>
                            </div>
                        </div>*/}
                        <div className="col-12 nav-list-outer">
                            <ul className="nav-list">
                                <li className={this.props.pathname === 'dashboard-sandbox' ? 'active' : ''}>
                                    <Link to="../dashboard-sandbox">
                                        <img src={require('../../images/nav-icon-1-greay.svg')} alt="" className="ni gi" />
                                        <img src={require('../../images/nav-icon-1-white.svg')} alt="" className="ni wi" />
                                        Dashboard
                                        <span className="na"><i className="fas fa-chevron-right"></i></span>
                                    </Link>
                                </li>
                                <li className={this.props.pathname === 'transactions-sandbox' ? 'active' : ''}>
                                    <Link to='transactions-sandbox'>
                                        <img src={require('../../images/nav-icon-2-greay.svg')} alt="" className="ni gi" />
                                        <img src={require('../../images/nav-icon-2-white.svg')} alt="" className="ni wi" />
                                        Transactions
                                        <span className="na"><i className="fas fa-chevron-right"></i></span>
                                    </Link>
                                </li>
                                <li className={this.props.pathname === 'settlement-sandbox' ? 'active' : ''}>
                                    <Link to='settlement-sandbox'>
                                        <img src={require('../../images/nav-icon-3-greay.svg')} alt="" className="ni gi" />
                                        <img src={require('../../images/nav-icon-3-white.svg')} alt="" className="ni wi" />
                                        Settlements
                                        <span className="na"><i className="fas fa-chevron-right"></i></span>
                                    </Link>
                                </li>
                                <li className={this.props.pathname === 'all-invoices-sandbox' ? 'active' : ''}>
                                    <Link to='all-invoices-sandbox'>
                                        <img src={require('../../images/nav-icon-4-greay.svg')} alt="" className="ni gi" />
                                        <img src={require('../../images/nav-icon-4-white.svg')} alt="" className="ni wi" />
                                        Invoices
                                        <span className="na"><i className="fas fa-chevron-right"></i></span>
                                    </Link>
                                </li>
                                <li className={this.props.pathname === 'payment-links-sandbox' ? 'active' : ''}>
                                    <Link to="../payment-links-sandbox">
                                        <img src={require('../../images/nav-icon-5-greay.svg')} alt="" className="ni gi" />
                                        <img src={require('../../images/nav-icon-5-white.svg')} alt="" className="ni wi" />
                                        Payment Links
                                        <span className="na"><i className="fas fa-chevron-right"></i></span>
                                    </Link>
                                </li>
                                <li className={this.props.pathname === 'reports-and-matrix-sandbox' ? 'active' : ''}>
                                    <Link to="../reports-and-matrix-sandbox">
                                        <img src={require('../../images/nav-icon-6-greay.svg')} alt="" className="ni gi" />
                                        <img src={require('../../images/nav-icon-6-white.svg')} alt="" className="ni wi" />
                                        Reports &amp; Metrics
                                        <span className="na"><i className="fas fa-chevron-right"></i></span>
                                    </Link>
                                </li>
                                <li className={this.props.pathname === 'customer-sandbox' ? 'active' : ''}>
                                    <Link to="../customer-sandbox">
                                        <img src={require('../../images/nav-icon-7-greay.svg')} alt="" className="ni gi" />
                                        <img src={require('../../images/nav-icon-7-white.svg')} alt="" className="ni wi" />
                                        Customers
                                        <span className="na"><i className="fas fa-chevron-right"></i></span>
                                    </Link>
                                </li>
                                <li className={this.props.pathname === 'settings-general' ? 'active' : ''}>
                                    <Link to="../settings-general">
                                        <img src={require('../../images/nav-icon-8-greay.svg')} alt="" className="ni gi" />
                                        <img src={require('../../images/nav-icon-8-white.svg')} alt="" className="ni wi" />
                                        Settings
                                        <span className="na"><i className="fas fa-chevron-right"></i></span>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col-12 nav-user">
                            <div className="row row_clr nu-in">
                                <a>
                                    <img src={sessionStorage.getItem("logo_image")} alt="user" className="user" style={{width:30}}/>
                                    {decode(this.state.doing_business_name)}
                                    <button type="button" onClick={this.showUserMenu} style={{background:"none",border:"none",outline:"none"}}>
                                        <img src={require('../../images/dots.svg')} alt="" id="dot-button" className="dots"  />
                                    </button>
                                </a>
                            </div>
                        </div>
                    </row>
                </div>
            </>
        );
    }
}