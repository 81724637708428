import React from "react";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Link, Navigate } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
import NumberFormat from "react-number-format";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { Navigation } from "./Navigation";
import { decode } from "html-entities";

import jsPDF from "jspdf";
import { myConfig } from "../config";
import { TopBar } from "./TopBar";
import ReactPaginate from "react-paginate";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import Modal from "react-bootstrap/Modal";
import {
  GetWebxLaravel,
  PostCompnentWebx,
  PostWebxLaravel,
} from "./PostCompnent";
import { Footer } from "./Footer";
import { toast, ToastContainer } from "react-toastify";
import { SupportButton } from "./SupportButton";
import LoadingSpinner from "./LoadingSpinner";

import {
  FacebookIcon,
  FacebookShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  ViberIcon,
  ViberShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { CopyToClipboard } from "react-copy-to-clipboard";
import PasswordChecklist from "react-password-checklist";

export class SettingsGeneral extends React.Component {
  generateQrCode() {
    let formData = new FormData(); //formdata object

    formData.append(
      "merchant_id",
      sessionStorage.getItem("merchant_id") +
        "|" +
        sessionStorage.getItem("encrypted_merchant_id")
    );
    formData.append("user_token", sessionStorage.getItem("laravel_token")); //append the values with key, value pair
    this.handleToggleProgress();
    PostWebxLaravel("links/encrypt_link", formData).then((response) => {
      this.handleCloseProgress();

      let payment_link = response.data;
      this.setState({
        payment_link: payment_link,
      });
    });
  }

  constructor(props) {
    super(props);
    this.state = {
      bank_name: "",
      settlement_status: "",
      social_account_facebook_link: "",
      social_account_instagram_link: "",
      social_account_twitter_link: "",
      business_information: [],
      setOpenProgress: false,
      openprogress: false,
      logo_file: require("../images/wbx_logo.png"),
      uploaded_file: "",
      error_upload: "",
      toasts: "",
      return_url: "",
      gateways: [],
      fee_array: [],
      text_additional: "",
      modalShowTex: "",
      setModalShowTex: "",
      amount: "",
      gateway_id: "",
      penrcentage: "",
      row_index: "",
      error_validate: [],
      public_key: "",
      secret_key: "",
      bin_lists: [],
      bin_lists_render: [],
      bin_list_filters: [],
      offsetBinList: 0,
      perPageBinList: 10,
      currentPageBinList: 0,
      pageCountBinList: 0,
      modalShowBin: false,
      setModalShowBin: false,
      manageBin: this.saveNewBin,
      bin_range_end: "",
      bin_range_start: "",
      bin_percentage: "",
      date_start: moment().format("YYYY-MM-DD"),
      date_start_date: moment().format("YYYY-MM-DD"),
      date_expire: "",
      store_bin_id: "",
      errors_bin: "",
      description: "",
      is_active: "",
      edit: false,
      bin_heading: "Add Bin Details",
      filter_active_status: "",
      filter_bin_range_start: "",
      filter_bin_range_end: "",
      filter_description: "",
      dayCountFilStart: 30,
      dayCountFilExp: 30,
      filter_date_start: "",
      filter_date_expire: "",
      promotion_available: false,
      validate_err: {},
      transactionModelOpen: false,
      setTransactionModelOpen: false,
      errors_password: [],
      old_password: "",
      new_password: "",
      confirm_password: "",
      modalShowCloseConfirmation: false,
      setModalShowCloseConfimation: false,
      password_change_message: "",
      modalShowConfirmBin: false,
      setModalShowConfirmBin: false,
      zz: "",
      payment_link: "",
      modalShowMyQr: false,
      setModalShowMyQr: false,
      return_url_staging: "",
      error_validate_stg: {},
      public_key_staging: "",
      secret_key_staging: "",
      staging_email: "",
      api_username: "",
      api_password: "",
      api_username_live: "",
      api_password_live: "",
      isValid: false,
      password_change: false,
      error_percentage: [],
      bankAccountModelOpen: false,
      setBankAccountModelOpen: false,

      account_holder_name: "",
      account_number: "",
      account_bank_name: "",
      branch_code: "",
      branch_name: "",
      rate: "",
    };
    this.generateQrCode = this.generateQrCode.bind(this);
    this.onChanges = this.onChanges.bind(this);
    this.handleCloseProgress = this.handleCloseProgress.bind(this);
    this.handleToggleProgress = this.handleToggleProgress.bind(this);
    this.getBusinessInformation = this.getBusinessInformation.bind(this);
    this.saveSocialMediaAccounts = this.saveSocialMediaAccounts.bind(this);
    this.fetchSocialAccounts = this.fetchSocialAccounts.bind(this);
    this.setLoadLogo = this.setLoadLogo.bind(this);
    this.saveLogoImage = this.saveLogoImage.bind(this);
    this.getImage = this.getImage.bind(this);
    this.notify = this.notify.bind(this);
    this.notifyReturn = this.notifyReturn.bind(this);
    this.notifyConvince = this.notifyConvince.bind(this);
    this.removeLogo = this.removeLogo.bind(this);
    this.saveGateways = this.saveGateways.bind(this);
    this.editConvienece = this.editConvienece.bind(this);
    this.getGateways = this.getGateways.bind(this);
    this.closeModalGroup = this.closeModalGroup.bind(this);
    this.showModalGroup = this.showModalGroup.bind(this);
    this.saveReturnUrl = this.saveReturnUrl.bind(this);
    this.saveReturnUrlStaging = this.saveReturnUrlStaging.bind(this);
    this.getGatewaysShow = this.getGatewaysShow.bind(this);
    this.showMerchantBinData = this.showMerchantBinData.bind(this);
    this.loadTableBinData = this.loadTableBinData.bind(this);
    this.handlePageBinData = this.handlePageBinData.bind(this);
    this.closeModalBin = this.closeModalBin.bind(this);
    this.showModalBin = this.showModalBin.bind(this);
    this.openEditModalBin = this.openEditModalBin.bind(this);
    this.saveNewBin = this.saveNewBin.bind(this);
    this.updateNewBin = this.updateNewBin.bind(this);
    this.handleValidationBin = this.handleValidationBin.bind(this);
    this.handleEvent = this.handleEvent.bind(this);
    this.handleEventEnd = this.handleEventEnd.bind(this);
    this.onChangeNumber = this.onChangeNumber.bind(this);
    this.merchantBinFilter = this.merchantBinFilter.bind(this);
    this.merchantBinClearFilter = this.merchantBinClearFilter.bind(this);
    this.handleCallbackStartDate = this.handleCallbackStartDate.bind(this);
    this.handleCallbackExpiry = this.handleCallbackExpiry.bind(this);
    this.checkPromotionsAvailable = this.checkPromotionsAvailable.bind(this);
    this.validateImage = this.validateImage.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
    this.openPasswordModal = this.openPasswordModal.bind(this);
    this.closePasswordModal = this.closePasswordModal.bind(this);
    this.handleValidation = this.handleValidation.bind(this);
    this.closeModalPasswordConfirm = this.closeModalPasswordConfirm.bind(this);
    this.openModelPasswordConfirm = this.openModelPasswordConfirm.bind(this);
    this.showModalConfirmBin = this.showModalConfirmBin.bind(this);
    this.closeModalConfirmBin = this.closeModalConfirmBin.bind(this);
    this.downloadQr = this.downloadQr.bind(this);
    this.notifyOther = this.notifyOther.bind(this);
    this.showModalMyQr = this.showModalMyQr.bind(this);
    this.closeModalMyQr = this.closeModalMyQr.bind(this);
    this.loadKeys = this.loadKeys.bind(this);
    this.loadReturnUrlStagingUrl = this.loadReturnUrlStagingUrl.bind(this);
    this.handleValidationPercentage =
      this.handleValidationPercentage.bind(this);
    this.openBankAccountModal = this.openBankAccountModal.bind(this);
    this.closePasswordModalBankAccount =
      this.closePasswordModalBankAccount.bind(this);
  }

  downloadQr() {
    const canvas = document.getElementById("merchant_qr");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = "merchant_qr.png";

    const pdf = new jsPDF();
    pdf.setFontSize(24);
    pdf.addImage(require("../images/webxlogo_png.png"), "PNG", 80, 15, 51, 18);
    // pdf.addImage(this.state.logo_file, 'PNG', 80, 30, 50, 50);
    pdf.text(this.state.business_information.doing_business_name, 45, 60);
    pdf.addImage(pngUrl, "PNG", 70, 75);
    pdf.save("download.pdf");
  }

  showModalConfirmBin() {
    this.setState({
      modalShowConfirmBin: true,
      setModalShowConfirmBin: true,
    });
  }

  closeModalConfirmBin() {
    this.setState({
      modalShowConfirmBin: false,
      setModalShowConfirmBin: false,
    });
  }

  showModalMyQr() {
    this.setState({
      modalShowMyQr: true,
      setModalShowMyQr: true,
    });
  }

  closeModalMyQr() {
    this.setState({
      modalShowMyQr: false,
      setModalShowMyQr: false,
    });
  }

  openModelPasswordConfirm() {
    this.setState({
      modalShowCloseConfirmation: true,
      setModalShowCloseConfimation: true,
    });
  }

  closeModalPasswordConfirm() {
    this.setState({
      modalShowCloseConfirmation: false,
      setModalShowCloseConfimation: false,
    });
  }

  openPasswordModal() {
    this.setState(
      {
        transactionModelOpen: true,
        setTransactionModelOpen: true,
      },
      () => {
        var modelD = document.getElementById("anis-modal");
        modelD.classList.add("ancd-mod");
      }
    );
  }
  openBankAccountModal() {
    let formData = new FormData(); //formdata object
    PostCompnentWebx("getStoreBankInfo", formData).then((response) => {
      if (response) {
        this.setState({
          account_holder_name: response.data.account_holder_name,
          account_number: response.data.account_number,
          account_bank_name: response.data.bank_name,
          branch_code: response.data.branch_code,
          branch_name: response.data.branch_name,
        });
      }
    });
    this.setState(
      {
        bankAccountModelOpen: true,
        setBankAccountModelOpen: true,
      },
      () => {
        var modelD = document.getElementById("anis-modal-bank");
        modelD.classList.add("ancd-mod-bank");
      }
    );
  }

  closePasswordModalBankAccount() {
    this.setState({
      bankAccountModelOpen: false,
      setBankAccountModelOpen: false,
    });
  }

  closePasswordModal() {
    this.setState({
      transactionModelOpen: false,
      setTransactionModelOpen: false,
    });
  }

  handleValidationPassword() {
    let errors_password = {};
    let formIsValid = true;
    //Email

    if (this.state.old_password == "") {
      formIsValid = false;
      errors_password["old_password"] = "Required Field";
    }
    if (this.state.new_password == "") {
      formIsValid = false;
      errors_password["new_password"] = "Required Field";
    }
    if (this.state.confirm_password == "") {
      formIsValid = false;
      errors_password["confirm_password"] = "Required Field";
    }

    if (this.state.new_password !== "" && this.state.old_password !== "") {
      if (this.state.new_password === this.state.old_password) {
        formIsValid = false;
        errors_password["new_password"] =
          "Your new password cannot be the same as your old password";
      }
    }
    this.setState({ errors_password: errors_password });

    if (this.state.isValid == false) {
      formIsValid = false;
    }

    return formIsValid;
  }

  resetPassword() {
    if (this.handleValidationPassword()) {
      // this.handleToggleProgress();
      this.setState({ loading: true });
      let formData = new FormData(); //formdata object
      formData.append("previous_password", this.state.old_password);
      formData.append("password", this.state.new_password);
      formData.append("confirm_password", this.state.confirm_password);

      PostCompnentWebx("updatePassword", formData).then((response) => {
        this.handleCloseProgress();
        this.closePasswordModal();

        this.setState(
          {
            password_change_message: response.data.explaination,
            old_password: "",
            loading: false,
            new_password: "",
            confirm_password: "",
          },
          () => {
            this.openModelPasswordConfirm();
          }
        );
      });
    }
  }

  loadKeys() {
    let BaseUrl = myConfig.laravelUrl;
    let lar_config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: sessionStorage.getItem("laravel_token"),
      },
    };
    // let formData = new FormData();    //formdata object
    //  formData.append('id', id);
    //formData.append('_method', "DELETE");
    this.handleToggleProgress();
    PostWebxLaravel(
      "wbx_merchant/load-merchant-keys/" +
        sessionStorage.getItem("merchant_id") +
        "|" +
        sessionStorage.getItem("encrypted_merchant_id")
    ).then((response) => {
      this.handleCloseProgress();
      this.setState({
        public_key_staging: response.data.public_key,
        secret_key_staging: response.data.secret_key,
        api_username: response.data.api_username,
        api_password: response.data.api_password,
      });

      this.loadReturnUrlStagingUrl(response.data.staging_email);
      this.setState({
        staging_email: response.data.staging_email,
      });
    });
  }

  loadReturnUrlStagingUrl(email) {
    let formData = new FormData(); //formdata object
    formData.append("email", email);
    this.handleToggleProgress();
    PostWebxLaravel(
      "staging/load-staging-url/" +
        sessionStorage.getItem("merchant_id") +
        "|" +
        sessionStorage.getItem("encrypted_merchant_id"),
      formData
    ).then((response) => {
      this.handleCloseProgress();
      /* console.log(secret_key);*/
      this.setState({
        return_url_staging: response.data.return_url_web,
      });
    });
  }

  checkPromotionsAvailable() {
    PostCompnentWebx("checkPromotionsAvailable").then((response) => {
      this.handleCloseProgress();
      let promotion_available = false;

      if (response.data.status == 1) {
        promotion_available = true;
      } else {
        promotion_available = false;
      }
      this.setState({
        promotion_available: promotion_available,
      });
    });
  }

  handleCallbackStartDate(event, picker) {
    this.setState({
      filter_date_start: picker.startDate.format("YYYY-MM-DD"),
    });
  }

  handleCallbackExpiry(event, picker) {
    this.setState({
      filter_date_expire: picker.endDate.format("YYYY-MM-DD"),
    });
  }

  merchantBinFilter() {
    let filter_active_status = this.state.filter_active_status;
    let filter_bin_range_start = this.state.filter_bin_range_start;
    let filter_bin_range_end = this.state.filter_bin_range_end;
    let filter_description = this.state.filter_description;
    let filter_date_start = this.state.filter_date_start;
    let filter_date_expire = this.state.filter_date_expire;

    let bin_lists = this.state.bin_lists,
      bin_list_filters = [];

    let BaseUrl = myConfig.apiUrl;
    let formData = new FormData(); //formdata object
    formData.append("is_active", filter_active_status);
    formData.append("bin_range_start", filter_bin_range_start);
    formData.append("bin_range_end", filter_bin_range_end);
    formData.append("date_start", filter_date_start);
    formData.append("date_expire", filter_date_expire);
    formData.append("description", filter_description);

    this.handleToggleProgress();
    PostCompnentWebx("getMerchantBIN", formData).then((response) => {
      this.handleCloseProgress();
      this.setState(
        {
          bin_lists: response.data,
          bin_list_filters: response.data,
        },
        () => {
          this.loadTableBinData();
          this.handleCloseProgress();
        }
      );
    });
  }

  merchantBinClearFilter() {
    let BaseUrl = myConfig.apiUrl;
    let formData = new FormData(); //formdata object
    formData.append("is_active", "");
    formData.append("bin_range_start", "");
    formData.append("bin_range_end", "");
    formData.append("date_start", "");
    formData.append("date_expire", "");
    formData.append("description", "");

    this.handleToggleProgress();
    PostCompnentWebx("getMerchantBIN", formData).then((response) => {
      this.handleCloseProgress();
      this.setState(
        {
          filter_active_status: "",
          filter_bin_range_start: "",
          filter_bin_range_end: "",
          filter_description: "",
          filter_date_start: "",
          filter_date_expire: "",
          bin_lists: response.data,
          bin_list_filters: response.data,
        },
        () => {
          this.loadTableBinData();
          this.handleCloseProgress();
        }
      );
    });
  }

  handleEvent(event, picker) {
    this.setState({
      date_start: picker.startDate.format("YYYY-MM-DD"),
    });
  }

  handleEventEnd(event, picker) {
    this.setState({
      date_expire: picker.endDate.format("YYYY-MM-DD"),
    });
  }

  handleValidationBin() {
    let errors_bin = {};
    let formIsValid = true;

    //Email
    if (
      this.state.bin_range_start == "" ||
      this.state.bin_range_start.length < 6
    ) {
      formIsValid = false;
      errors_bin["bin_range_start"] =
        "Required Field / Length should be more than 6";
    }

    if (this.state.bin_range_end !== "") {
      if (this.state.bin_range_end.length < 6) {
        formIsValid = false;
        errors_bin["bin_range_end"] = "Length should be more than 6";
      }
    }
    if (this.state.is_active == "") {
      formIsValid = false;
      errors_bin["is_active"] = "Required Field";
    }

    if (this.state.description == "") {
      formIsValid = false;
      errors_bin["description"] = "Required Field";
    }

    const re = /-?^[0-9]+$/;

    // if value is not blank, then test the regex

    if (parseFloat(this.state.bin_percentage) < -100) {
      formIsValid = false;
      errors_bin["bin_percentage"] =
        "Invalid entry should be less than or equal to 100";
    } else {
      if (re.test(this.state.bin_percentage)) {
        if (this.state.bin_percentage == 0) {
        } else {
          formIsValid = false;
          errors_bin["bin_percentage"] =
            "Invalid entry should be negative or zero";
        }
      }
    }
    if (this.state.bin_percentage == "") {
      formIsValid = false;
      errors_bin["bin_percentage"] = "Required Field";
    }
    if (this.state.date_start == "") {
      formIsValid = false;
      errors_bin["date_start"] = "Required Field";
    }
    if (this.state.date_expire == "") {
      formIsValid = false;
      errors_bin["date_expire"] = "Required Field";
    }

    var parse_end_d = Date.parse(this.state.date_expire);
    var parse_start_d = Date.parse(this.state.date_start);

    if (parse_end_d - parse_start_d < 0) {
      formIsValid = false;
      errors_bin["date_expire"] = "Expiry date backdated";
      errors_bin["date_start"] = "Expiry date backdated";
    }

    this.setState({ errors_bin: errors_bin });
    return formIsValid;
  }

  openEditModalBin(
    store_bin_id,
    bin_range_start,
    bin_range_end,
    percentage,
    date_start,
    date_expire,
    is_active,
    description
  ) {
    this.setState(
      {
        text_bin: "Update Bin",
        bin_heading: "Edit Bin Details",
        modalShowBin: true,
        setModalShowBin: true,
        edit: true,
        bin_range_start: bin_range_start,
        description: description,
        bin_range_end: bin_range_end,
        bin_percentage: percentage,
        date_start: date_start,
        date_expire: date_expire,
        store_bin_id: store_bin_id,
        is_active: is_active,
        manageBin: this.updateNewBin,
      },
      () => {
        var modelD = document.getElementById("activation-modal");
        modelD.classList.add("ancd-mod");
      }
    );
  }

  saveNewBin() {
    if (this.handleValidationBin()) {
      this.setState({
        error_message: "",
      });
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      let BaseUrl = myConfig.laravelUrl;
      let lar_config = {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: sessionStorage.getItem("laravel_token"),
        },
      };
      let formData = new FormData(); //formdata object
      let bin_range_start = this.state.bin_range_start;
      let bin_range_end = "";
      if (this.state.bin_range_end == "") {
        bin_range_end = this.state.bin_range_start;
      } else {
        bin_range_end = this.state.bin_range_end;
      }

      let bin_percentage = this.state.bin_percentage;
      let date_start = this.state.date_start;
      let date_expire = this.state.date_expire;
      let is_active = this.state.is_active;
      let description = this.state.description;

      formData.append("bin_range_start", bin_range_start); //append the values with key, value pair
      formData.append("bin_range_end", bin_range_end);
      formData.append("percentage", bin_percentage);
      formData.append("date_start", date_start);
      formData.append("date_expire", date_expire);
      formData.append("is_active", is_active);
      formData.append("description", description);

      this.handleToggleProgress();
      PostCompnentWebx("addMerchantBIN", formData).then((response) => {
        this.setState({
          bin_integration_message: response.data.explaination,
        });
        this.handleCloseProgress();
        this.showMerchantBinData();

        this.closeModalBin();
        this.showModalConfirmBin();
      });
    }
  }

  updateNewBin() {
    if (this.handleValidationBin()) {
      this.setState({
        error_message: "",
      });
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      let BaseUrl = myConfig.laravelUrl;
      let lar_config = {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: sessionStorage.getItem("laravel_token"),
        },
      };
      let formData = new FormData(); //formdata object
      let bin_range_start = this.state.bin_range_start;
      let bin_range_end = this.state.bin_range_end;
      let bin_percentage = this.state.bin_percentage;
      let date_start = this.state.date_start;
      let date_expire = this.state.date_expire;
      let store_bin_id = this.state.store_bin_id;
      let is_active = this.state.is_active;
      let description = this.state.description;
      /*
                        formData.append('bin_range_start', bin_range_start);*/ //append the values with key, value pair
      /* formData.append('bin_range_end', bin_range_end);*/
      formData.append("percentage", bin_percentage);
      formData.append("date_start", date_start);
      formData.append("date_expire", date_expire);
      formData.append("store_bin_id", store_bin_id);
      formData.append("is_active", is_active);
      formData.append("description", description);

      this.handleToggleProgress();
      PostCompnentWebx("updateMerchantBIN", formData).then((response) => {
        this.setState({
          bin_integration_message: response.data.explaination,
        });

        this.handleCloseProgress();
        this.showMerchantBinData();
        this.closeModalBin();
        this.showModalConfirmBin();
      });
    }
  }

  showModalBin() {
    this.setState(
      {
        text_bin: "Save",
        modalShowBin: true,
        setModalShowBin: true,
        edit: false,
        manageBin: this.saveNewBin,
        bin_heading: "Add Bin Details",
        bin_range_end: "",
        bin_range_start: "",
        bin_percentage: "",
        date_start: moment().format("YYYY-MM-DD"),
        date_expire: "",
        store_bin_id: "",
        description: "",
      },
      () => {
        var modelD = document.getElementById("activation-modal");
        modelD.classList.add("ancd-mod");
      }
    );
  }

  closeModalBin() {
    this.setState({
      modalShowBin: false,
      setModalShowBin: false,
    });
  }

  notifyConvince() {
    toast("Convenience  fee updated successfully");
  }

  showMerchantBinData() {
    this.handleToggleProgress();
    PostCompnentWebx("getMerchantBIN").then((response) => {
      this.handleCloseProgress();

      if (response.data !== null) {
        this.setState(
          {
            bin_lists: response.data,
            bin_list_filters: response.data,
          },
          () => {
            this.loadTableBinData();
          }
        );
      }
    });
  }

  handlePageBinData = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPageBinList;
    this.setState(
      {
        currentPageBinList: selectedPage,
        offsetBinList: offset,
      },
      () => {
        this.loadTableBinData();
      }
    );
  };

  loadTableBinData() {
    const list_data = this.state.bin_list_filters;
    const zeroPad = (num, places) => String(num).padStart(places, "0");

    const slice = list_data.slice(
      this.state.offsetBinList,
      this.state.offsetBinList + this.state.perPageBinList
    );
    const trans =
      list_data && list_data.length ? (
        slice.map((list, key) => (
          <tr className="">
            <td className="" data-title="#">
              {key + 1}
            </td>
            <td className="" data-title="Type">
              {list.description}
            </td>

            <td className="" data-title="Type">
              {list.bin_range_start}
            </td>
            <td className="" data-title="Type">
              {list.bin_range_end}
            </td>
            <td className="" data-title="Type">
              {list.percentage}
            </td>
            <td className="" data-title="Type">
              {list.date_start}
            </td>
            <td className="" data-title="Type">
              {list.date_expire}
            </td>
            <td className="" data-title="Type">
              {list.is_active == 1 ? "Active" : "Inactive"}
            </td>

            <td className="" data-title="" style={{ textAlign: "center" }}>
              <div className="dropdown">
                <button
                  className="btn dropdown-toggle dot-drop"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  onClick={() =>
                    this.openEditModalBin(
                      list.store_bin_id,
                      list.bin_range_start,
                      list.bin_range_end,
                      list.percentage,
                      list.date_start,
                      list.date_expire,
                      list.is_active,
                      list.description
                    )
                  }
                >
                  <i className="fas fa-edit"></i>
                </button>
                <div
                  className="dropdown-menu table-drop"
                  aria-labelledby="dropdownMenuButton"
                >
                  <button
                    className="dropdown-item"
                    data-toggle="modal"
                    data-target="#edit-tax-modal"
                  >
                    <i className="fas fa-pencil-alt" aria-hidden="true"></i>
                  </button>
                  <button className="dropdown-item">
                    <i className="fas fa-trash-alt" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
            </td>
          </tr>
        ))
      ) : (
        <tr className="">
          <td colSpan="4" className="" data-title="#">
            No Results Found
          </td>
        </tr>
      );
    this.setState({
      pageCountBinList: Math.ceil(list_data.length / this.state.perPageBinList),
      bin_lists_render: trans,
    });
  }

  handleValidationPercentage() {
    // let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    if (this.state.penrcentage == "") {
      formIsValid = false;
      errors["penrcentage"] =
        "Please enter a valid number (You can set the value to 0)";
    }
    this.setState({ error_percentage: errors });
    return formIsValid;
  }

  handleValidation() {
    // let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    if (this.state.return_url !== "") {
      if (!this.isUrlValid(this.state.return_url)) {
        formIsValid = false;
        errors["return_url"] = "Invalid URL";
      }
    }
    this.setState({ error_validate: errors });
    return formIsValid;
  }

  isUrlValid = (userInput) => {
    var res = userInput.match(
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
    );
    if (res == null) return false;
    else return true;
  };

  saveReturnUrl() {
    /*   if (this.handleValidation()) {*/
    let formData = new FormData(); //formdata object
    formData.append("return_url_web", this.state.return_url);

    this.handleToggleProgress();
    PostCompnentWebx("updateStoreReturnURL", formData).then((response) => {
      if (response) {
        this.getGateways();
        this.notifyReturn();
        this.handleCloseProgress();
      }
    });
    /* }*/
  }

  saveReturnUrlStaging() {
    if (this.handleValidationReturnUrlStaging()) {
      let formData = new FormData(); //formdata object
      formData.append("return_url_staging", this.state.return_url_staging);
      formData.append("email", this.state.staging_email);

      this.handleToggleProgress();
      PostWebxLaravel(
        "wbx_merchant/update-store-return-staging",
        formData
      ).then((response) => {
        if (response) {
          this.getGateways();
          this.notifyReturn();
          this.handleCloseProgress();
        }
      });
    }
  }

  handleValidationReturnUrlStaging() {
    let error_validate_stg = {};
    let formIsValid = true;
    //Email

    if (this.state.return_url_staging == "") {
      formIsValid = false;
      error_validate_stg["return_url_staging"] = "Required Field";
    }

    this.setState({ error_validate_stg: error_validate_stg });
    return formIsValid;
  }

  showModalGroup(gateway_id, percentage, key) {
    this.setState(
      {
        text_additional: "Save",
        modalShowTex: true,
        setModalShowTex: true,
        amount: "",
        row_index: key,
        gateway_id: gateway_id,
        penrcentage: percentage,
      },
      () => {
        var modelD = document.getElementById("activation-modal");
        modelD.classList.add("ancd-mod");
      }
    );
  }

  closeModalGroup() {
    this.setState({
      modalShowTex: false,
      setModalShowTex: false,
    });
  }

  getGatewaysShow() {
    let formData = new FormData(); //formdata object

    PostCompnentWebx("getStorePaymentGatewaysWithCategory").then((response) => {
      this.handleCloseProgress();
      this.setState({
        gateways: response.data,
      });
      this.setState({ fee_array: [] });
      for (let i = 0; i < response.data.length; i++) {
        let new_keys = response.data[i].store_payment_gateway_id;
        let percentage = response.data[i].convenience_fee_percentage;
        let gateway = response.data[i].display;

        this.setState((prevState) => ({
          fee_array: [
            ...prevState.fee_array,
            {
              percentage: percentage,
              gateway_id: new_keys,
              gateway: gateway,
            },
          ],
        }));
      }
    });
    PostCompnentWebx("getStoreReturnURL").then((response) => {
      this.handleCloseProgress();
      this.setState({
        return_url: response.data.return_url_web,
      });
    });
  }

  getGateways() {
    let formData = new FormData(); //formdata object

    PostCompnentWebx("getStorePaymentGatewaysWithCategory").then((response) => {
      this.handleCloseProgress();
      this.setState({
        gateways: response.data,
      });
      for (let i = 0; i < response.data.length; i++) {
        let new_keys = response.data[i].store_payment_gateway_id;
        let percentage = response.data[i].convenience_fee_percentage;
        let gateway = response.data[i].display;

        this.setState((prevState) => ({
          fee_array: [
            ...prevState.fee_array,
            {
              percentage: percentage,
              gateway_id: new_keys,
              gateway: gateway,
            },
          ],
        }));
      }
    });
    PostCompnentWebx("getStoreReturnURL").then((response) => {
      this.handleCloseProgress();
      this.setState({
        return_url: response.data.return_url_web,
      });
    });
  }

  saveGateways() {
    let formData = new FormData(); //formdata object
    const newArray = new Array(); // Create a copy

    let itemsArr = [];
    this.state.fee_array.forEach((item) => {
      itemsArr.push({
        store_payment_gateway_id: item.gateway_id,
        convenience_fee_percentage: item.percentage,
      });
    });
    formData.append("store_payment_gateway", JSON.stringify(itemsArr));

    this.handleToggleProgress();
    PostCompnentWebx(
      "updateStorePaymentGatewayConvenienceFeePercentage",
      formData
    ).then((response) => {
      if (response) {
        this.getGatewaysShow();
        this.notifyConvince();
        this.handleCloseProgress();
      }
    });
  }

  editConvienece() {
    if (this.handleValidationPercentage()) {
      // 1. Make a shallow copy of the items
      let fee_arrays = [...this.state.fee_array];
      // 2. Make a shallow copy of the item you want to mutate
      let fee_array = { ...fee_arrays[this.state.row_index] };
      // 3. Replace the property you're intested in

      fee_array.percentage = this.state.penrcentage;

      fee_arrays[this.state.row_index] = fee_array;
      // 5. Set the state to our new copy
      this.setState({ fee_array: fee_arrays });
      this.closeModalGroup();
    }
  }

  notifyOther() {
    toast("Link Copied");
  }

  notify() {
    toast("Profile Updated");
  }

  notifyReturn() {
    toast("Return url saved successfully");
  }

  removeLogo() {
    let formData = new FormData(); //formdata object
    formData.append("_method", "put");
    formData.append("upload_profile", ""); //append the values with key, value pair
    this.handleToggleProgress();
    PostWebxLaravel(
      "merchants/update_merchant/" +
        sessionStorage.getItem("merchant_id") +
        "|" +
        sessionStorage.getItem("encrypted_merchant_id"),
      formData
    ).then((response) => {
      // this.handleCloseProgress();
      //this.notify();
      // this.getImage();
      window.location.reload();
      if (response.data.status === 200) {
        sessionStorage.removeItem("logo_image");
        this.setState({
          logo_file: require("../images/wbx_logo.png"),
        });
      } else {
        this.setState({
          error_upload: response.data.image_path,
        });
      }
    });
  }

  validateImage() {
    let validate_err = {};
    let formIsValid = false;
    //Email
    let file_name = this.state.uploaded_file.name.split(".").pop();
    if (this.state.uploaded_file == "") {
      formIsValid = false;
    }

    if (file_name == "png" || file_name == "jpg" || file_name == "jpeg") {
      formIsValid = true;
    } else {
      formIsValid = false;
      validate_err["uploaded_file"] =
        "File type not valid should be *.jpg, *.png or *.jpeg";
    }

    this.setState({ validate_err: validate_err });
    return formIsValid;
  }

  saveLogoImage() {
    if (this.validateImage()) {
      let formData = new FormData(); //formdata object
      formData.append("_method", "put");
      formData.append("upload_profile", this.state.uploaded_file); //append the values with key, value pair
      formData.append("user_token", sessionStorage.getItem("userToken")); //append the values with key, value pair
      this.handleToggleProgress();
      PostWebxLaravel(
        "merchants/update_merchant/" +
          sessionStorage.getItem("merchant_id") +
          "|" +
          sessionStorage.getItem("encrypted_merchant_id"),
        formData
      ).then(
        (response) => {
          // this.handleCloseProgress();
          //this.notify();
          window.location.reload();
          if (response.data.status === 200) {
            this.getImage();
          } else {
            this.setState({
              error_upload: response.data.image_path,
            });
          }
        },
        () => {}
      );
    }
  }

  getImage() {
    GetWebxLaravel(
      "merchants/get_merchant_data/" +
        sessionStorage.getItem("merchant_id") +
        "|" +
        sessionStorage.getItem("encrypted_merchant_id")
    ).then((response) => {
      this.handleCloseProgress();
      this.setState({
        public_key: response.data.public_key,
        secret_key: response.data.secret_key,
        api_username_live: response.data.api_username,
        api_password_live: response.data.api_password,
      });
    });
    GetWebxLaravel(
      "merchants/get_image/" +
        sessionStorage.getItem("merchant_id") +
        "|" +
        sessionStorage.getItem("encrypted_merchant_id")
    ).then((response) => {
      this.handleCloseProgress();
      sessionStorage.removeItem("logo_image");
      sessionStorage.setItem("logo_image", response.data);
      this.setState({
        logo_file:
          response.data !== null
            ? response.data
            : require("../images/wbx_logo.png"),
      });
    });
  }

  setLoadLogo(e) {
    if (typeof e.target.files[0] !== "undefined") {
      this.setState({
        logo_file: URL.createObjectURL(e.target.files[0]),
        uploaded_file: e.target.files[0],
      });
    }
  }

  saveSocialMediaAccounts() {
    let formData = new FormData(); //formdata object

    formData.append(
      "social_account_facebook_link",
      this.state.social_account_facebook_link
    ); //append the values with key, value pair
    formData.append(
      "social_account_instagram_link",
      this.state.social_account_instagram_link
    );
    formData.append(
      "social_account_twitter_link",
      this.state.social_account_twitter_link
    );
    formData.append(
      "merchant_id",
      sessionStorage.getItem("merchant_id") +
        "|" +
        sessionStorage.getItem("encrypted_merchant_id")
    );
    this.handleToggleProgress();
    PostWebxLaravel("webx_merchant_social/create_social", formData).then(
      (response) => {
        this.handleCloseProgress();
        this.fetchSocialAccounts();
      }
    );
  }

  fetchSocialAccounts() {
    this.handleToggleProgress();
    GetWebxLaravel(
      "webx_merchant_social/get_social_account/" +
        sessionStorage.getItem("merchant_id") +
        "|" +
        sessionStorage.getItem("encrypted_merchant_id")
    ).then((response) => {
      if (typeof response !== "undefined") {
        this.handleCloseProgress();
        let data = response.data;
        this.setState({
          social_account_facebook_link:
            response.data.social_account_facebook_link,
          social_account_instagram_link:
            response.data.social_account_instagram_link,
          social_account_twitter_link:
            response.data.social_account_twitter_link,
        });
      }
    });
  }

  getBusinessInformation() {
    let formData = new FormData(); //formdata object
    this.handleToggleProgress();
    PostCompnentWebx("getStoreBusinessInfo", formData).then((response) => {
      if (response) {
        this.handleCloseProgress();
        this.setState({
          business_information: response.data[0],
        });
      }
    });
  }

  onChangeNumber(e) {
    if (e.target.name == "product_rate") {
      const re = /^-?[0-9.\b]+$/;
      if (e.target.value == "" || re.test(e.target.value)) {
        this.setState({ [e.target.name]: e.target.value });
      }
      if (parseFloat(this.state.total_amount) < parseFloat(e.target.value)) {
        this.setState({ [e.target.name]: e.target.value });
      }
    } else {
      const re = /^-?[0-9.\b]+$/;
      if (e.target.value === "" || re.test(e.target.value)) {
        this.setState({ [e.target.name]: e.target.value });
      }
    }

    // if value is not blank, then test the regex
  }

  onChanges(e) {
    if (e.target.name == "new_password") {
      this.setState({ password_change: true });
    }

    if (e.target.name == "bin_percentage") {
      let percentage = e.target.value;
      if (percentage.length < 6) {
        if (e.target.value == 0) {
          e.target.value = 0;
          this.setState({
            bin_percentage: e.target.value,
          });
        } else {
          this.setState({
            bin_percentage: -Math.abs(percentage),
          });
        }
      }
    } else {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  }

  handleCloseProgress = () => {
    this.setState({ setOpenProgress: false });
    this.setState({ openprogress: false });
  };
  handleToggleProgress = () => {
    this.setState({ setOpenProgress: true });
    this.setState({ openprogress: true });
  };

  componentWillMount() {
    if (
      sessionStorage.getItem("userToken") ||
      localStorage.getItem("userToken")
    ) {
      this.getImage();
      this.getGateways();
      this.generateQrCode();

      var userToken =
        sessionStorage.getItem("userToken") ||
        localStorage.getItem("userToken");
      this.showMerchantBinData();
      this.getBusinessInformation();
      this.checkPromotionsAvailable();
      this.checkPromotionsAvailable();
      this.setState({ userToken: userToken });
    } else {
      this.setState({ redirect: true });
    }
    this.handleToggleProgress();
    GetWebxLaravel(
      "webx_merchant_social/get_social_account/" +
        sessionStorage.getItem("merchant_id") +
        "|" +
        sessionStorage.getItem("encrypted_merchant_id")
    ).then((response) => {
      if (typeof response !== "undefined") {
        this.handleCloseProgress();
        let data = response.data;
        this.setState({
          social_account_facebook_link:
            response.data.social_account_facebook_link,
          social_account_instagram_link:
            response.data.social_account_instagram_link,
          social_account_twitter_link:
            response.data.social_account_twitter_link,
        });
      }
    });
  }

  render() {
    const total_list_length = 30 - this.state.description.length;

    let payment_link = this.state.payment_link;
    var QRCode = require("qrcode.react");

    const bin_lists = this.state.bin_lists_render;
    const renderThumb = ({ style, ...props }) => {
      const thumbStyle = {
        borderRadius: 6,
        backgroundColor: "rgba(35, 49, 86, 0.8)",
      };
      return <div style={{ ...style, ...thumbStyle }} {...props} />;
    };
    const CustomScrollbars = (props) => (
      <Scrollbars
        renderThumbHorizontal={renderThumb}
        renderThumbVertical={renderThumb}
        {...props}
      />
    );
    const fee_array = this.state.fee_array;

    const getways = this.state.gateways;
    const business_information = this.state.business_information;
    var base64 = require("base-64");
    var public_key = base64.decode(this.state.public_key);
    // var public_key_staging = base64.decode(this.state.public_key_staging);

    if (this.state.redirect) {
      return <Navigate to="/login" />;
    }

    return (
      <div>
        <SupportButton />

        <Modal
          show={this.state.bankAccountModelOpen}
          onHide={this.state.setBankAccountModelOpen}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          id="anis-modal-bank"
          className="refund-modal"
        >
          <Modal.Body className="modal-type-2">
            <div className="container-fluid">
              <div className="row" style={{ justifyContent: "flex-end" }}>
                <div className="col-12 cd-frm">
                  <h3
                    className="tdh"
                    style={{ fontSize: "26px", margin: "10px 0 15px" }}
                  >
                    Bank Account Details
                  </h3>

                  <div
                    className="col-12 tdm-in p-0"
                    style={{ marginBottom: "5px" }}
                  >
                    <span style={{ fontWeight: "700", marginRight: "5px" }}>
                      Account Name:
                    </span> {this.state.account_holder_name}
                  </div>
                  <div
                    className="col-12 tdm-in p-0"
                    style={{ marginBottom: "5px" }}
                  >
                    <span style={{ fontWeight: "700", marginRight: "5px" }}>
                      Account Number:
                    </span> {this.state.account_number}
                  </div>

                  <div
                    className="col-12 tdm-in p-0"
                    style={{ marginBottom: "5px" }}
                  >
                    <span style={{ fontWeight: "700", marginRight: "5px" }}>
                      Bank:
                    </span> {this.state.account_bank_name}
                  </div>

                  <div
                    className="col-12 tdm-in p-0"
                    style={{ marginBottom: "5px" }}
                  >
                    <span style={{ fontWeight: "700", marginRight: "5px" }}>
                      Bank Branch:
                    </span> {this.state.branch_name}
                  </div>

                  <div
                    className="col-12 tdm-in p-0"
                    style={{ marginBottom: "5px" }}
                  >
                    <span style={{ fontWeight: "700", marginRight: "5px" }}>
                      Branch Code:
                    </span> {this.state.branch_code}
                  </div>
                </div>
                <button
                  className="btn btn-danger"
                  onClick={this.closePasswordModalBankAccount}
                >
                  Close
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.modalShowCloseConfirmation}
          onHide={this.state.setModalShowCloseConfimation}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          id="info-modal"
        >
          <div id="divcontents">
            <Modal.Body className="modal-type-2">
              {/* <div className="colse-mdl" data-dismiss="modal" aria-label="Close" onClick={this.modalClose}>X</div>*/}
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12 lft">
                    <h5 className="in-h">
                      {this.state.password_change_message}
                    </h5>
                    <button
                      className="btn btn-danger close-btn"
                      onClick={this.closeModalPasswordConfirm}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </div>
        </Modal>
        <Modal
          show={this.state.transactionModelOpen}
          onHide={this.state.setTransactionModelOpen}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          id="anis-modal"
          className="refund-modal"
        >
          <Modal.Body className="modal-type-2">
            <div>
              <h1>Password Reset</h1>

              <p>
                <strong>Enter Old Password</strong>
              </p>
              <div className="form-group">
                <input
                  type="password"
                  className="form-control"
                  value={this.state.old_password}
                  name="old_password"
                  placeholder="Enter Old Password"
                  onChange={this.onChanges}
                />
                <span className="error" style={{ color: "red" }}>
                  {this.state.errors_password["old_password"]}
                </span>
              </div>
              <p>
                <strong>Choose a New Password</strong>
              </p>
              <div className="form-group">
                <input
                  type="password"
                  className="form-control"
                  value={this.state.new_password}
                  name="new_password"
                  placeholder="Choose a New Password"
                  onChange={this.onChanges}
                />
                <span className="error" style={{ color: "red" }}>
                  {this.state.errors_password["new_password"]}
                </span>
              </div>
              <p>
                <strong>Confirm Your New Password</strong>
              </p>
              <div className="form-group">
                <input
                  type="password"
                  className="form-control"
                  value={this.state.confirm_password}
                  name="confirm_password"
                  placeholder="Confirm Your New Password"
                  onChange={this.onChanges}
                />
                <span className="error" style={{ color: "red" }}>
                  {this.state.errors_password["confirm_password"]}
                </span>
              </div>

              {this.state.password_change == true ? (
                <PasswordChecklist
                  rules={[
                    "minLength",
                    "specialChar",
                    "number",
                    "capital",
                    "match",
                  ]}
                  minLength={10}
                  value={this.state.new_password}
                  valueAgain={this.state.confirm_password}
                  onChange={(isValid) => {
                    this.setState({ isValid });
                  }}
                />
              ) : (
                ""
              )}

              <button
                className="btn btn-danger"
                onClick={this.closePasswordModal}
              >
                Cancel
              </button>
              {this.state.loading ? (
                <LoadingSpinner />
              ) : (
                <button
                  className="btn btn-success"
                  onClick={this.resetPassword}
                >
                  Reset
                </button>
              )}
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={this.state.modalShowMyQr}
          onHide={this.state.setModalShowMyQr}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          id="anis-modal"
          className="refund-modal my-qr-modal"
        >
          <Modal.Body className="modal-type-2">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.closeModalMyQr}
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <div>
              <div className="col-md-12" style={{ marginTop: "40px" }}>
                <div className="row">
                  <div className="col-12 details-box d-inv-box">
                    <div className="col-12 dbx-in">
                      {payment_link !== "" ? (
                        <div className="row row_clr dit-in">
                          <div className="col-12 lht">
                            <div className="row">
                              {/*<div className="col-12 lgo">*/}
                              {/*    <img src="images/invoice/l1.png" alt="" />*/}
                              {/*</div>*/}
                              <div className="col-md-6 col-12">
                                <QRCode value={payment_link} size={220} />

                                <div
                                  style={{ display: "none" }}
                                  className="HpQrcode"
                                >
                                  {" "}
                                  // hidden div
                                  <QRCode
                                    value={payment_link}
                                    size={260}
                                    id="merchant_qr"
                                    level={"H"}
                                    includeMargin={true}
                                  />
                                  <h5 className="mh">
                                    {business_information &&
                                    business_information.doing_business_name
                                      ? decode(
                                          business_information.doing_business_name
                                        )
                                      : ""}
                                  </h5>
                                </div>
                                <p className="qrp">
                                  <NumberFormat
                                    value={(
                                      Math.round(
                                        this.state.payment_amount_print * 100
                                      ) / 100
                                    ).toFixed(2)}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={
                                      this.state.currency_id == 5
                                        ? "LKR "
                                        : "USD "
                                    }
                                  />
                                </p>
                              </div>
                              <ToastContainer />

                              <div className="col-md-6 col-12">
                                <div className="sd">
                                  <h5>Share</h5>
                                  <FacebookShareButton url={payment_link}>
                                    <FacebookIcon
                                      size={40}
                                      logoFillColor="white"
                                    />
                                  </FacebookShareButton>
                                  <TwitterShareButton url={payment_link}>
                                    <TwitterIcon
                                      size={40}
                                      logoFillColor="white"
                                    />
                                  </TwitterShareButton>

                                  <WhatsappShareButton url={payment_link}>
                                    <WhatsappIcon
                                      size={40}
                                      logoFillColor="white"
                                    />
                                  </WhatsappShareButton>

                                  <ViberShareButton url={payment_link}>
                                    <ViberIcon
                                      size={40}
                                      logoFillColor="white"
                                    />
                                  </ViberShareButton>

                                  <TelegramShareButton url={payment_link}>
                                    <TelegramIcon
                                      size={40}
                                      logoFillColor="white"
                                    />
                                  </TelegramShareButton>

                                  <CopyToClipboard
                                    size={40}
                                    text={payment_link}
                                  >
                                    <button
                                      onClick={this.notifyOther}
                                      className="cpy-btn"
                                      style={{ transform: "translateY(1px)" }}
                                    >
                                      <i className="far fa-copy"></i>
                                    </button>
                                  </CopyToClipboard>
                                </div>

                                <a
                                  style={{ cursor: "pointer" }}
                                  onClick={this.downloadQr}
                                  className="p-btn plpb"
                                  target="_blank"
                                >
                                  Download QR &nbsp;&nbsp;
                                  <i className="fas fa-download"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <p className="det-p">
                      {this.state.payment_link_description}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          id="activation-modal"
          show={this.state.modalShowBin}
          onHide={this.state.setModalShowBin}
        >
          <Modal.Body className="modal-type-2">
            <div
              className="colse-mdl"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.closeModalBin}
            >
              X
            </div>
            <div className="container-fluid p-4">
              <div className="row">
                <div className="col-12 cd-frm">
                  <h3 className="tdh">{this.state.bin_heading}</h3>

                  <div className="col-12 tdm-in p-0">
                    <p
                      style={{
                        color: "#69a52e",
                        fontWeight: "600",
                        fontSize: "18px",
                      }}
                    >
                      Bin start and end range cannot be edited once entered
                    </p>
                    <h6>Description </h6>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        name="description"
                        placeholder="Enter Description"
                        onChange={this.onChanges}
                        value={this.state.description}
                        maxLength="30"
                      />
                      <span style={{ color: "red" }}>
                        {this.state.errors_bin["description"]}
                      </span>
                      <span style={{ color: "#cccccc" }}>
                        {total_list_length} Words remaining
                      </span>
                    </div>
                    <h6>Bin Range Start </h6>
                    <div className="form-group">
                      {this.state.edit == true ? (
                        <input
                          type="text"
                          className="form-control"
                          name="bin_range_start"
                          placeholder="Enter Bin Range Start"
                          onChange={this.onChangeNumber}
                          value={this.state.bin_range_start}
                          maxLength="6"
                          readOnly
                        />
                      ) : (
                        <input
                          type="text"
                          className="form-control"
                          name="bin_range_start"
                          placeholder="Enter Bin Range Start"
                          onChange={this.onChangeNumber}
                          value={this.state.bin_range_start}
                          maxLength="6"
                        />
                      )}
                      <span style={{ color: "red" }}>
                        {this.state.errors_bin["bin_range_start"]}
                      </span>
                    </div>

                    <div className="col-12  p-0">
                      <h6>Bin Range End</h6>
                      <div className="form-group">
                        {this.state.edit == true ? (
                          <input
                            type="text"
                            className="form-control"
                            name="bin_range_end"
                            placeholder="Enter Bin Range End"
                            onChange={this.onChangeNumber}
                            value={this.state.bin_range_end}
                            maxLength="6"
                            readOnly
                          />
                        ) : (
                          <input
                            type="text"
                            className="form-control"
                            name="bin_range_end"
                            placeholder="Enter Bin Range End"
                            onChange={this.onChangeNumber}
                            value={this.state.bin_range_end}
                            maxLength="6"
                          />
                        )}
                        <span style={{ color: "red" }}>
                          {this.state.errors_bin["bin_range_end"]}
                        </span>
                      </div>
                    </div>

                    <div className="col-12  p-0">
                      <h6>Discount (%)</h6>
                      <div className="form-group">
                        <input
                          type="number"
                          className="form-control"
                          name="bin_percentage"
                          placeholder="Enter Bin Discount"
                          onChange={this.onChanges}
                          value={this.state.bin_percentage}
                        />
                        <span style={{ color: "red" }}>
                          {this.state.errors_bin["bin_percentage"]}
                        </span>
                      </div>
                    </div>

                    <div className="col-12  p-0">
                      <h6>Start Date</h6>
                      <div className="form-group">
                        <DateRangePicker
                          initialSettings={{
                            singleDatePicker: true,
                            showDropdowns: true,
                            locale: {
                              format: "YYYY-MM-DD",
                            },
                            parentEl: "#activation-modal .modal-body",
                            autoApply: true,
                            autoUpdateInput: false,
                            minDate: this.state.date_start_date,
                            startDate: this.state.date_start,
                          }}
                          onApply={this.handleEvent}
                          onHideCalendar={this.handleEvent}
                          onCancel={this.handleEvent}
                          onHide={this.handleEvent}
                        >
                          <input
                            type="text"
                            className="form-control"
                            name="date_start"
                            placeholder="Enter Start Date"
                            onChange={this.onChanges}
                            value={this.state.date_start}
                          />
                        </DateRangePicker>
                        <span style={{ color: "red" }}>
                          {this.state.errors_bin["date_start"]}
                        </span>
                      </div>
                    </div>
                    <div className="col-12  p-0">
                      <h6>Expiry Date</h6>
                      <div className="form-group">
                        <DateRangePicker
                          initialSettings={{
                            singleDatePicker: true,
                            showDropdowns: true,
                            autoApply: true,
                            autoUpdateInput: false,
                            minDate: this.state.date_start,
                            locale: {
                              format: "YYYY-MM-DD",
                            },
                            parentEl: "#activation-modal .modal-body",
                          }}
                          onApply={this.handleEventEnd}
                          endDate={this.state.date_expire}
                        >
                          <input
                            type="text"
                            className="form-control"
                            name="date_expire"
                            placeholder="Enter Expiry Date"
                            onChange={this.onChanges}
                            value={this.state.date_expire}
                          />
                        </DateRangePicker>
                        <span style={{ color: "red" }}>
                          {this.state.errors_bin["date_expire"]}
                        </span>
                      </div>
                    </div>

                    <div className="col-12  p-0">
                      <h6> Status</h6>
                      <div className="form-group">
                        <select
                          name="is_active"
                          id=""
                          value={this.state.is_active}
                          className="form-control"
                          onChange={this.onChanges}
                        >
                          <option value=""> --Select--</option>
                          <option value="1">Active</option>
                          <option value="0">Inactive</option>
                        </select>
                        <span style={{ color: "red" }}>
                          {this.state.errors_bin["is_active"]}
                        </span>
                      </div>
                    </div>
                  </div>
                  <p style={{ color: "red" }}>{this.state.error_message}</p>
                  <button
                    type="button"
                    className="nxt-btn"
                    onClick={this.state.manageBin}
                  >
                    {this.state.text_bin} &nbsp;&nbsp;
                    <i className="fas fa-save" aria-hidden="true"></i>
                  </button>
                  {/*<a href="#" className="help"><i className="far fa-life-ring"
                                                                    aria-hidden="true"></i> Need Help with the
                                        System?</a>*/}
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          id="activation-modal"
          show={this.state.modalShowTex}
          onHide={this.state.setModalShowTex}
        >
          <Modal.Body className="modal-type-2">
            <div
              className="colse-mdl"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.closeModalGroup}
            >
              X
            </div>
            <div className="container-fluid p-4">
              <div className="row">
                <div className="col-12 cd-frm">
                  <h3 className="tdh">
                    Update Discount / Convenience Fee (+/- in percentage value)
                  </h3>

                  <div className="col-12 tdm-in p-0">
                    <div className="form-group">
                      <input
                        type="number"
                        className="form-control"
                        name="penrcentage"
                        placeholder="Enter Amount"
                        value={this.state.penrcentage}
                        onChange={this.onChangeNumber}
                      />

                      <span style={{ color: "red" }}>
                        {this.state.error_percentage["penrcentage"]}
                      </span>
                    </div>
                  </div>
                  <p style={{ color: "red" }}>{this.state.error_message}</p>
                  <button
                    type="button"
                    className="nxt-btn"
                    onClick={() => this.editConvienece()}
                  >
                    {this.state.text_additional} &nbsp;&nbsp;
                    <i className="fas fa-save" aria-hidden="true"></i>
                  </button>
                  {/*      <a href="#" className="help"><i className="far fa-life-ring"
                                                                    aria-hidden="true"></i> Need Help with the
                                        System?</a>*/}
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.modalShowConfirmBin}
          onHide={this.state.setModalShowConfirmBin}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          id="info-modal"
        >
          <div id="divcontents">
            <Modal.Body className="modal-type-2">
              {/* <div className="colse-mdl" data-dismiss="modal" aria-label="Close" onClick={this.modalClose}>X</div>*/}
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12 lft">
                    <h5 className="in-h">
                      {this.state.bin_integration_message}
                    </h5>
                    <button
                      className="btn btn-danger close-btn"
                      onClick={this.closeModalConfirmBin}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </div>
        </Modal>

        <Backdrop className="backdrop-loading" open={this.state.openprogress}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <div
          id="outer-container"
          className="container-fluid color-orange outer-container-inside-page"
        >
          <div id="turn-box"></div>
          <Navigation pathname="settings-general" />
          <div id="main-content" className="row row_clr dashboard-live-mode">
            <TopBar pathname="settings-general" />
            <div className="col-12 transaction-top">
              <ul className="nav nav-tabs">
                <li className="nav-item">
                  <Link
                    className="nav-link active"
                    id="tt-tab"
                    to="../settings-general"
                  >
                    <span className="tt">Business Configuration</span>
                    <span className="amt">General</span>
                  </Link>
                </li>
                {/*   <li className="nav-item">
                                    <Link className="nav-link" id="tr-tab" to="../settings-currency"><span className="tt">Currency & Exchange Rates</span><span
                                        className="amt">Currency</span></Link>
                                </li>*/}

                {sessionStorage.getItem("qr_enable") == 1 ? (
                  <>
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        id="tp-tab"
                        to="../invoice-settings"
                      >
                        <span className="tt">
                          Invoices, Payment Links & Taxes
                        </span>
                        <span className="amt">Invoices</span>
                      </Link>
                    </li>
                  </>
                ) : (
                  ""
                )}

                {sessionStorage.getItem("qr_enable") == 0 ? (
                  <>
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        id="tp-tab"
                        to="../invoice-settings"
                      >
                        <span className="tt">
                          Invoices, Payment Links & Taxes
                        </span>
                        <span className="amt">Invoices</span>
                      </Link>
                    </li>
                  </>
                ) : (
                  ""
                )}

                <li className="nav-item">
                  <Link className="nav-link" id="tp-tab" to="../settings-user">
                    <span className="tt">Roles, Profiles, and Permissions</span>
                    <span className="amt">User Accounts</span>
                  </Link>
                </li>

                {/*<li className="nav-item">
                  <Link className="nav-link" id="tp-tab" to="../subscription-settings">
                    <span className="tt">Payments, History, Upcoming Payments and Package Change</span>
                    <span className="amt">Subscriptions</span>
                  </Link>
                </li>*/}

              </ul>

              <div className="row row_clr settings-outer">

                <Tab.Container id="left-tabs-example" defaultActiveKey="one">
                  <div className="col-12 lft-sd">
                    <div className="col-12 lfs-in">
                      <div className="upload-btn-wrapper">
                        <button className="btn">
                          <img alt="" src={this.state.logo_file} />
                        </button>
                        <input
                          type="file"
                          name="myfile"
                          accept="image/jpg,image/jpeg,image/png"
                          onChange={this.setLoadLogo}
                        />
                        <p style={{ color: "red" }}>
                          {this.state.error_upload}
                        </p>
                        <p style={{ color: "red" }}>
                          {this.state.validate_err["uploaded_file"]}
                        </p>
                      </div>

                      <button
                        type="button"
                        className="nxt-btn"
                        onClick={this.saveLogoImage}
                      >
                        Save Logo &nbsp;&nbsp;
                        <i className="fas fa-save" aria-hidden="true"></i>
                      </button>
                      <button
                        type="button"
                        className="ext-btn ext-t2"
                        onClick={this.removeLogo}
                      >
                        Remove logo &nbsp;&nbsp;
                        <i className="fas fa-trash-alt" aria-hidden="true"></i>
                      </button>
                    </div>

                    <Nav variant="pills" className="nav nav-tabs" id="set-tab1">
                      <Nav.Item>
                        <Nav.Link eventKey="one" className="nav-link">
                          Business Information
                        </Nav.Link>
                      </Nav.Item>

                      {sessionStorage.getItem("qr_enable") == 0 ? (
                        <Nav.Item>
                          <Nav.Link eventKey="four" className="nav-link">
                            Website Integration
                          </Nav.Link>
                        </Nav.Item>
                      ) : (
                        ""
                      )}

                      {this.state.promotion_available &&
                      sessionStorage.getItem("qr_enable") == 0 ? (
                        <Nav.Item>
                          <Nav.Link eventKey="five" className="nav-link">
                            Bin Setup
                          </Nav.Link>
                        </Nav.Item>
                      ) : (
                        ""
                      )}
                      {this.state.promotion_available &&
                      sessionStorage.getItem("qr_enable") == 1 ? (
                        <Nav.Item>
                          <Nav.Link eventKey="five" className="nav-link">
                            Bin Setup
                          </Nav.Link>
                        </Nav.Item>
                      ) : (
                        ""
                      )}

                      {sessionStorage.getItem("qr_enable") == 0 ? (
                        <Nav.Item>
                          <Nav.Link
                            eventKey="six"
                            className="nav-link"
                            onClick={this.loadKeys}
                          >
                            Sandbox Environment
                          </Nav.Link>
                        </Nav.Item>
                      ) : (
                        ""
                      )}
                    </Nav>
                    {sessionStorage.getItem("qr_enable") == 1 ? (
                      <button
                        style={{ position: "initial" }}
                        type="button"
                        className="nxt-btn qr-button"
                        onClick={this.showModalMyQr}
                      >
                        My Qr &nbsp;&nbsp;<i className="fas fa-qrcode"></i>
                      </button>
                    ) : (
                      ""
                    )}
                    {sessionStorage.getItem("qr_enable") == 0 ? (
                      <button
                        style={{ position: "initial" }}
                        type="button"
                        className="nxt-btn qr-button"
                        onClick={this.showModalMyQr}
                      >
                        My Qr &nbsp;&nbsp;<i className="fas fa-qrcode"></i>
                      </button>
                    ) : (
                      ""
                    )}

                    <button
                      style={{ position: "initial" }}
                      type="button"
                      className="nxt-btn chg-pwd"
                      onClick={this.openPasswordModal}
                    >
                      Change Password &nbsp;&nbsp;
                      <i className="fas fa-lock" aria-hidden="true"></i>
                    </button>

                    <button
                      style={{ position: "initial" }}
                      type="button"
                      className="nxt-btn chg-pwd"
                      onClick={this.openBankAccountModal}
                    >
                      Bank Account Details &nbsp;&nbsp;
                      <i
                        className="fas fa-money-check-alt"
                        aria-hidden="true"
                      ></i>
                    </button>
                  </div>
                  <div className="col-12 rht-sd">
                    <div className="tab-content">
                      <ToastContainer />
                      <Tab.Content id="v-pills-tabContent">
                        <Tab.Pane eventKey="one" id="v-pills-1">
                          <div className="row row_clr">
                            <div className="col-12">
                              <h3 className="tdh">Business Information</h3>
                              <h4 className="sth">General Information</h4>
                            </div>
                            <div className="col-md-6 col-xs-12">
                              <h6 className="sh">Name of Company/Business</h6>
                              <h5 className="mh">
                                {business_information.registered_name
                                  ? decode(business_information.registered_name)
                                  : ""}
                              </h5>
                            </div>
                            <div className="col-md-6 col-xs-12">
                              <h6 className="sh">Doing Business Name</h6>
                              <h5 className="mh">
                                {business_information.doing_business_name
                                  ? decode(
                                      business_information.doing_business_name
                                    )
                                  : ""}
                              </h5>
                            </div>
                            <div className="col-md-6 col-xs-12">
                              <h6 className="sh">Nature of Business</h6>
                              <h5 className="mh">
                                {business_information.category_description
                                  ? business_information.category_description
                                  : ""}
                              </h5>
                            </div>
                            <div className="col-md-6 col-xs-12">
                              <h6 className="sh">Company Website</h6>
                              <h5 className="mh">
                                {business_information.website_url
                                  ? business_information.website_url
                                  : ""}{" "}
                              </h5>
                            </div>
                            <div className="col-md-4 col-xs-12">
                              <h6 className="sh">Business Email</h6>
                              <h5
                                className="mh"
                                style={{ wordBreak: "break-all" }}
                              >
                                {business_information.email_address
                                  ? business_information.email_address
                                  : ""}
                              </h5>
                            </div>
                            <div className="col-md-4 col-xs-12">
                              <h6 className="sh">Business Telephone</h6>
                              <h5 className="mh">
                                {business_information.contact_number
                                  ? business_information.contact_number
                                  : ""}
                              </h5>
                            </div>
                            <div className="col-md-4 col-xs-12">
                              <h6 className="sh">
                                Business Fax Number (Optional)
                              </h6>
                              <h5 className="mh">---</h5>
                            </div>

                            <div className="col-12">
                              <h4 className="sth">Company Address Details</h4>
                            </div>

                            <div className="col-12">
                              <h6 className="sh">Street Address</h6>
                              <h5 className="mh">
                                {business_information.address_line_one
                                  ? business_information.address_line_one
                                  : ""}
                                ,{" "}
                                {business_information.address_line_two
                                  ? business_information.address_line_two
                                  : ""}
                              </h5>
                            </div>
                            <div className="col-md-4 col-xs-12">
                              <h6 className="sh">City</h6>
                              <h5 className="mh">
                                {business_information.city
                                  ? business_information.city
                                  : ""}
                              </h5>
                            </div>
                            <div className="col-md-4 col-xs-12">
                              <h6 className="sh">ZIP Code</h6>
                              <h5 className="mh">
                                {business_information.postal_code
                                  ? business_information.postal_code
                                  : ""}
                              </h5>
                            </div>
                            <div className="col-md-4 col-xs-12">
                              <h6 className="sh">Country</h6>
                              <h5 className="mh">
                                {business_information.country}
                              </h5>
                            </div>
                            <div className="col-md-4 col-xs-12">
                              <h6 className="sh">
                                Business Registration Number
                              </h6>
                              <h5 className="mh">
                                {business_information.business_registration_number
                                  ? business_information.business_registration_number
                                  : ""}
                              </h5>
                            </div>

                            <div className="col-12">
                              <h4 className="sth">Social Media Accounts</h4>
                            </div>

                            <div className="col-md-4 soc-in col-xs-12">
                              <img
                                src={require("../images/settings/facebook.svg")}
                                alt=""
                              />
                              <h6 className="sh">Facebook</h6>
                              <div className="form-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Add Facebook Link"
                                  name="social_account_facebook_link"
                                  value={
                                    this.state.social_account_facebook_link
                                      ? this.state.social_account_facebook_link
                                      : ""
                                  }
                                  onChange={this.onChanges}
                                />
                              </div>
                            </div>
                            <div className="col-md-4 soc-in col-xs-12">
                              <img
                                src={require("../images/settings/linkedin.svg")}
                                alt=""
                              />
                              <h6 className="sh">Instagram</h6>
                              <div className="form-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Add Instagram Link"
                                  name="social_account_instagram_link"
                                  value={
                                    this.state.social_account_instagram_link
                                      ? this.state.social_account_instagram_link
                                      : ""
                                  }
                                  onChange={this.onChanges}
                                />
                              </div>
                            </div>
                            <div className="col-md-4 soc-in col-xs-12">
                              <img
                                src={require("../images/settings/twitter.svg")}
                                alt=""
                              />
                              <h6 className="sh">Twitter</h6>
                              <div className="form-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Add Twitter Link"
                                  name="social_account_twitter_link"
                                  value={
                                    this.state.social_account_twitter_link
                                      ? this.state.social_account_twitter_link
                                      : ""
                                  }
                                  onChange={this.onChanges}
                                />
                              </div>
                            </div>
                            <div className="col-12">
                              <button
                                type="button"
                                className="nxt-btn"
                                onClick={this.saveSocialMediaAccounts}
                              >
                                Save Social Media Accounts &nbsp;&nbsp;
                                <i
                                  className="fas fa-save"
                                  aria-hidden="true"
                                ></i>
                              </button>
                            </div>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="two" id="v-pills-2">
                          <div className="row row_clr">
                            <div className="col-12">
                              <h3 className="tdh">Registration Information</h3>
                              <h4 className="sth">Registration Information</h4>
                            </div>
                            <div className="col-md-6 col-xs-12">
                              <h6 className="sh">
                                Business Registration Number
                              </h6>
                              <h5 className="mh">PV-99998</h5>
                            </div>
                            <div className="col-md-6 col-xs-12">
                              <h6 className="sh">Date of Registration</h6>
                              <h5 className="mh">September 18, 2009</h5>
                            </div>
                            <div className="col-md-6 col-xs-12">
                              <h6 className="sh">
                                Business Registration Certificate
                              </h6>
                              <h5 className="mh">
                                Meridian_Creative_Services_BR_Scan
                              </h5>
                            </div>
                            <div className="col-md-6 col-xs-12">
                              <h6 className="sh">Form 01 Document</h6>
                              <h5 className="mh">
                                Meridian_Creative_Services_F1_Scan
                              </h5>
                            </div>
                            <div className="col-12">
                              <h4 className="sth">
                                Director/Shareholder Information
                              </h4>
                            </div>
                            <div className="col-12">
                              <div className="rg-container">
                                <table
                                  className="transactions-table"
                                  summary="Hed"
                                >
                                  <thead>
                                    <tr>
                                      <th className="">#</th>
                                      <th className="">Shareholder Name</th>
                                      <th className="">Designation</th>
                                      <th className="">Share %</th>
                                      <th className="">NIC Number</th>
                                      <th className="">
                                        Political Affiliations
                                      </th>
                                      <th className=""></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr className="">
                                      <td className="" data-title="#">
                                        001
                                      </td>
                                      <td
                                        className=""
                                        data-title="Shareholder Name"
                                      >
                                        Sebastian Vettel
                                      </td>
                                      <td className="" data-title="Designation">
                                        Chief Executive Officer
                                      </td>
                                      <td className="" data-title="Share %">
                                        75.00
                                      </td>
                                      <td className="" data-title="NIC Number">
                                        923732883v
                                      </td>
                                      <td
                                        className=""
                                        data-title="Political Affiliations"
                                      >
                                        None
                                      </td>
                                      <td className="" data-title="">
                                        <div className="dropdown">
                                          <button
                                            className="btn dropdown-toggle dot-drop"
                                            type="button"
                                            id="dropdownMenuButton"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                          >
                                            ...
                                          </button>
                                          <div
                                            className="dropdown-menu table-drop"
                                            aria-labelledby="dropdownMenuButton"
                                          >
                                            <button className="dropdown-item">
                                              <i
                                                className="fas fa-pencil-alt"
                                                aria-hidden="true"
                                              ></i>
                                            </button>
                                            <button className="dropdown-item">
                                              <i
                                                className="fas fa-trash-alt"
                                                aria-hidden="true"
                                              ></i>
                                            </button>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="three" id="v-pills-3">
                          <div className="row row_clr">
                            <div className="col-12">
                              <h3 className="tdh">User Management</h3>
                            </div>
                            <div className="col-12 mt-3">
                              <div className="rg-container">
                                <table
                                  className="transactions-table"
                                  summary="Hed"
                                >
                                  <thead>
                                    <tr>
                                      <th className="">#</th>
                                      <th className="">User Name</th>
                                      <th className="">User Role</th>
                                      <th className="">Email Address</th>
                                      <th className="">Status</th>
                                      <th
                                        className=""
                                        style={{ textAlign: "center" }}
                                      >
                                        <i
                                          className="fas fa-plus"
                                          aria-hidden="true"
                                          style={{ cursor: "pointer" }}
                                          data-toggle="modal"
                                          data-target="#add-new-customer-modal"
                                        ></i>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr className="">
                                      <td className="" data-title="#">
                                        001
                                      </td>
                                      <td className="" data-title="User Name">
                                        Sebastian Vettel
                                      </td>
                                      <td className="" data-title="User Role">
                                        Owner
                                      </td>
                                      <td
                                        className=""
                                        data-title="Email Address"
                                      >
                                        viraj@wearemeridian.com
                                      </td>
                                      <td
                                        className="pop-td"
                                        data-title="Status"
                                      >
                                        <span className="dot yellow">
                                          <i
                                            className="fas fa-circle"
                                            aria-hidden="true"
                                          ></i>
                                        </span>{" "}
                                        Settlement Pending
                                      </td>
                                      <td className="" data-title="">
                                        <div className="dropdown">
                                          <button
                                            className="btn dropdown-toggle dot-drop"
                                            type="button"
                                            id="dropdownMenuButton"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                          >
                                            ...
                                          </button>
                                          <div
                                            className="dropdown-menu table-drop"
                                            aria-labelledby="dropdownMenuButton"
                                          >
                                            <button
                                              className="dropdown-item"
                                              data-toggle="modal"
                                              data-target="#edit-customer-modal"
                                            >
                                              <i
                                                className="fas fa-pencil-alt"
                                                aria-hidden="true"
                                              ></i>
                                            </button>
                                            <button className="dropdown-item">
                                              <i
                                                className="fas fa-trash-alt"
                                                aria-hidden="true"
                                              ></i>
                                            </button>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="four" id="v-pills-1">
                          <div
                            className="tab-pane fade show active"
                            id="th"
                            role="tabpanel"
                            aria-labelledby="th-tab"
                          >
                            <div className="row row_clr">
                              <div className="col-12">
                                <h3 className="tdh">Integration</h3>
                              </div>
                              <div className="col-12 ps-keys">
                                <h6>Public Key</h6>
                                <textarea
                                  name="public_key"
                                  className="form-control tfc"
                                  cols="30"
                                  rows="6"
                                  onChange={this.onChanges}
                                  readOnly
                                  value={public_key}
                                  placeholder="Payment Description"
                                ></textarea>
                                <h6>Secret Key</h6>
                                <textarea
                                  name="public_key"
                                  className="form-control tfc"
                                  cols="30"
                                  rows="1"
                                  onChange={this.onChanges}
                                  readOnly
                                  value={this.state.secret_key}
                                  placeholder="Payment Description"
                                ></textarea>
                                <h6>Api Username</h6>
                                <textarea
                                  name="public_key"
                                  className="form-control tfc"
                                  cols="30"
                                  rows="1"
                                  onChange={this.onChanges}
                                  readOnly
                                  value={this.state.api_username_live}
                                  placeholder="Api Username"
                                ></textarea>
                                <h6>Api Password</h6>
                                <textarea
                                  name="public_key"
                                  className="form-control tfc"
                                  cols="30"
                                  rows="1"
                                  onChange={this.onChanges}
                                  readOnly
                                  value={this.state.api_password_live}
                                  placeholder="Api Password"
                                ></textarea>
                              </div>
                              <div className="col-12">
                                <h4
                                  className="sth"
                                  style={{ marginBottom: -15 }}
                                >
                                  Add Return URL
                                </h4>
                              </div>

                              <div className="col-12 set-fi">
                                <h6 className="sh"></h6>
                                <div className="form-group">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder=""
                                    name="return_url"
                                    onChange={this.onChanges}
                                    value={this.state.return_url}
                                  />
                                </div>
                                <span
                                  className="error"
                                  style={{ color: "red" }}
                                >
                                  {this.state.error_validate["return_url"]}
                                </span>
                              </div>

                              <div className="col-12">
                                <button
                                  type="button"
                                  className="nxt-btn"
                                  onClick={this.saveReturnUrl}
                                >
                                  Save Return URL &nbsp;&nbsp;
                                  <i
                                    className="fas fa-save"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                              </div>

                              <div className="col-12">
                                <div className="rg-container"></div>
                                <div className="row row_clr">
                                  <div className="col-12">
                                    <h3 className="tdh">Convenience fee</h3>
                                    <h4 className="sth">
                                      Manage Convenience fee
                                    </h4>
                                  </div>
                                  <div className="col-12">
                                    <div
                                      className="rg-container"
                                      style={{ height: 300 }}
                                    >
                                      <CustomScrollbars
                                        autoHide
                                        autoHideTimeout={500}
                                        autoHideDuration={200}
                                      >
                                        <table
                                          className="transactions-table"
                                          summary="Hed"
                                        >
                                          <thead>
                                            <tr>
                                              <th className="">Gateway</th>
                                              <th className="">
                                                Discount / Convenience Fee (+/-
                                                in percentage value)
                                              </th>
                                              <th className=""></th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {fee_array && fee_array.length
                                              ? fee_array.map((list, key) => {
                                                  return list.gateway_id !==
                                                    "63008" ? (
                                                    <tr>
                                                      <td>{list.gateway}</td>
                                                      <td>{list.percentage}</td>
                                                      <td>
                                                        <i
                                                          className="fa fa-pencil"
                                                          aria-hidden="true"
                                                          onClick={() =>
                                                            this.showModalGroup(
                                                              list.gateway_id,
                                                              list.percentage,
                                                              key
                                                            )
                                                          }
                                                        ></i>
                                                      </td>
                                                    </tr>
                                                  ) : (
                                                    ""
                                                  );
                                                })
                                              : ""}
                                          </tbody>
                                        </table>
                                      </CustomScrollbars>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-12">
                                <button
                                  type="button"
                                  className="nxt-btn"
                                  onClick={this.saveGateways}
                                >
                                  Save &nbsp;&nbsp;
                                  <i
                                    className="fas fa-save"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </Tab.Pane>
                        {this.state.promotion_available ? (
                          <Tab.Pane eventKey="five" id="v-pills-1">
                            <div
                              className="tab-pane fade show active"
                              id="to"
                              role="tabpanel"
                              aria-labelledby="to-tab"
                            >
                              <div className="row row_clr">
                                <div className="col-12">
                                  <h3 className="tdh">Bin Setup</h3>
                                </div>
                                <div className="col-12 bin-info-form">
                                  <div className="row">
                                    <div className="col-3">
                                      <label>Status</label>
                                      <div className="form-group">
                                        <select
                                          name="filter_active_status"
                                          id=""
                                          value={
                                            this.state.filter_active_status
                                          }
                                          className="form-control"
                                          onChange={this.onChanges}
                                        >
                                          <option value=""> --Select--</option>
                                          <option value="1">Active</option>
                                          <option value="0">Inactive</option>
                                        </select>
                                      </div>
                                    </div>
                                    <div className="col-3">
                                      <label>Bin Range Start</label>
                                      <div className="form-group">
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="filter_bin_range_start"
                                          placeholder=""
                                          onChange={this.onChangeNumber}
                                          value={
                                            this.state.filter_bin_range_start
                                          }
                                          maxLength="8"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-3">
                                      <label>Bin Range End</label>
                                      <div className="form-group">
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="filter_bin_range_end"
                                          placeholder=""
                                          onChange={this.onChangeNumber}
                                          value={
                                            this.state.filter_bin_range_end
                                          }
                                          maxLength="8"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-3">
                                      <label>Description</label>
                                      <div className="form-group">
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="filter_description"
                                          placeholder=""
                                          onChange={this.onChanges}
                                          value={this.state.filter_description}
                                          maxLength="8"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-3">
                                      <label>Start Date</label>
                                      <div className="form-group">
                                        <DateRangePicker
                                          initialSettings={{
                                            singleDatePicker: true,
                                            showDropdowns: true,
                                            locale: {
                                              format: "YYYY-MM-DD",
                                            },
                                            parentEl:
                                              "#activation-modal .modal-body",
                                            autoApply: true,
                                            autoUpdateInput: false,
                                          }}
                                          onApply={this.handleCallbackStartDate}
                                          onHideCalendar={
                                            this.handleCallbackStartDate
                                          }
                                          onCancel={
                                            this.handleCallbackStartDate
                                          }
                                          onHide={this.handleCallbackStartDate}
                                        >
                                          <input
                                            type="text"
                                            className="form-control"
                                            name="filter_date_start"
                                            placeholder="Enter Start Date"
                                            onChange={this.onChanges}
                                            value={this.state.filter_date_start}
                                          />
                                        </DateRangePicker>
                                      </div>
                                    </div>
                                    <div className="col-3">
                                      <label>Expiry Date</label>
                                      <div className="form-group">
                                        <DateRangePicker
                                          initialSettings={{
                                            singleDatePicker: true,
                                            showDropdowns: true,
                                            locale: {
                                              format: "YYYY-MM-DD",
                                            },
                                            parentEl:
                                              "#activation-modal .modal-body",
                                            autoApply: true,
                                            autoUpdateInput: false,
                                          }}
                                          onApply={this.handleCallbackExpiry}
                                          onHideCalendar={
                                            this.handleCallbackExpiry
                                          }
                                          onCancel={this.handleCallbackExpiry}
                                          onHide={this.handleCallbackExpiry}
                                        >
                                          <input
                                            type="text"
                                            className="form-control"
                                            name="filter_date_expire"
                                            placeholder="Enter Expiry Date"
                                            onChange={this.onChanges}
                                            value={
                                              this.state.filter_date_expire
                                            }
                                          />
                                        </DateRangePicker>
                                      </div>
                                    </div>

                                    <div className="col-3">
                                      <button
                                        type="button"
                                        className="nxt-btn"
                                        style={{
                                          float: "left",
                                          marginTop: "-5px",
                                          marginBottom: "15px",
                                          border: "2px solid #2b2935",
                                          background: "#ffffff",
                                          color: "#2b2935",
                                        }}
                                        onClick={this.merchantBinFilter}
                                      >
                                        Search &nbsp;&nbsp;{" "}
                                      </button>
                                    </div>
                                    <div className="col-3">
                                      <button
                                        type="button"
                                        className="nxt-btn"
                                        style={{
                                          float: "left",
                                          marginTop: "-5px",
                                          marginBottom: "15px",
                                        }}
                                        onClick={this.merchantBinClearFilter}
                                      >
                                        Clear &nbsp;&nbsp;{" "}
                                      </button>
                                    </div>
                                  </div>

                                  <div className="row row_clr">
                                    <button
                                      type="button"
                                      className="nxt-btn"
                                      style={{
                                        float: "left",
                                        marginTop: "5px",
                                        marginBottom: "20px",
                                      }}
                                      onClick={this.showModalBin}
                                    >
                                      Add New Bin Discount &nbsp;&nbsp;{" "}
                                      <i
                                        className="fas fa-plus-circle"
                                        aria-hidden="true"
                                        style={{
                                          cursor: "pointer",
                                          fontSize: "20px",
                                          transform: "translateY(2px)",
                                        }}
                                        data-toggle="modal"
                                        data-target="#add-new-tax-modal"
                                      ></i>
                                    </button>
                                  </div>
                                  <div className="rg-container">
                                    <table
                                      className="transactions-table"
                                      summary="Hed"
                                    >
                                      <thead>
                                        <tr>
                                          <th className="">#</th>
                                          <th className="">Description</th>
                                          <th className="">Bin Range Start</th>
                                          <th className="">Bin Range End</th>
                                          <th className="">Discount(%)</th>
                                          <th className=""> Start Date</th>
                                          <th className=""> Expiry Date</th>
                                          <th className=""> Status</th>
                                          <th></th>
                                        </tr>
                                      </thead>
                                      <tbody>{bin_lists}</tbody>
                                    </table>
                                    <ReactPaginate
                                      previousLabel={"PREV"}
                                      nextLabel={"NEXT"}
                                      breakLabel={"..."}
                                      breakClassName={"break-me"}
                                      pageCount={this.state.pageCountBinList}
                                      marginPagesDisplayed={2}
                                      pageRangeDisplayed={5}
                                      onPageChange={this.handlePageBinData}
                                      containerClassName={"pagination"}
                                      subContainerClassName={"pages pagination"}
                                      activeClassName={"active"}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Tab.Pane>
                        ) : (
                          ""
                        )}

                        <Tab.Pane eventKey="six" id="v-pills-1">
                          <div
                            className="tab-pane fade show active"
                            id="to"
                            role="tabpanel"
                            aria-labelledby="to-tab"
                          >
                            <div className="row row_clr">
                              <div className="col-12">
                                <h3 className="tdh">Sandbox Environment</h3>
                              </div>
                              <div className="col-12 ps-keys">
                                <h6>Public Key</h6>
                                <textarea
                                  name="public_key"
                                  className="form-control tfc"
                                  cols="30"
                                  rows="6"
                                  onChange={this.onChanges}
                                  readOnly
                                  value={this.state.public_key_staging}
                                  placeholder="Payment Description"
                                ></textarea>
                                <h6>Secret Key</h6>
                                <textarea
                                  name="public_key"
                                  className="form-control tfc"
                                  cols="30"
                                  rows="1"
                                  onChange={this.onChanges}
                                  readOnly
                                  value={this.state.secret_key_staging}
                                  placeholder="Payment Description"
                                ></textarea>
                                <h6>Api Username</h6>
                                <textarea
                                  name="public_key"
                                  className="form-control tfc"
                                  cols="30"
                                  rows="1"
                                  readOnly
                                  value={this.state.api_username}
                                  placeholder="Payment Description"
                                ></textarea>
                                <h6>Api Password</h6>
                                <textarea
                                  name="public_key"
                                  className="form-control tfc"
                                  cols="30"
                                  rows="1"
                                  readOnly
                                  value={this.state.api_password}
                                  placeholder="Payment Description"
                                ></textarea>

                                <div className="col-12">
                                  <h4
                                    className="sth"
                                    style={{ marginBottom: -15 }}
                                  >
                                    Add Return URL
                                  </h4>
                                </div>

                                <div className="col-12 set-fi">
                                  <h6 className="sh"></h6>
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder=""
                                      name="return_url_staging"
                                      onChange={this.onChanges}
                                      value={this.state.return_url_staging}
                                    />
                                  </div>
                                  <span
                                    className="error"
                                    style={{ color: "red" }}
                                  >
                                    {
                                      this.state.error_validate_stg[
                                        "return_url_staging"
                                      ]
                                    }
                                  </span>
                                </div>

                                <div className="col-12">
                                  <button
                                    type="button"
                                    className="nxt-btn"
                                    onClick={this.saveReturnUrlStaging}
                                  >
                                    Save Return URL &nbsp;&nbsp;
                                    <i
                                      className="fas fa-save"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                  <a
                                    type="button"
                                    className="nxt-btn"
                                    href="http://webxpay.co/Integration/Integration-pack.zip"
                                    style={{
                                      marginRight: "5px",
                                      textDecoration: "none",
                                    }}
                                  >
                                    Download Integration Pack &nbsp;&nbsp;
                                    <i
                                      className="fas fa-download"
                                      aria-hidden="true"
                                    ></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Tab.Pane>
                      </Tab.Content>
                      {/*     <div className="tab-pane fade show active" id="to" role="tabpanel"
                                             aria-labelledby="tr-tab">

                                        </div>
                                        <div className="tab-pane fade" id="tr" role="tabpanel" aria-labelledby="tr-tab">

                                        </div>
                                        <div className="tab-pane fade" id="th" role="tabpanel" aria-labelledby="tr-tab">

                                        </div>*/}
                    </div>
                  </div>
                </Tab.Container>
              </div>
            </div>

            <Footer />
          </div>
        </div>
      </div>
    );
  }
}
