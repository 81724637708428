import React from "react";
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from "axios";
import {Link, Navigate} from "react-router-dom";
import {myConfig} from "../config";
import {headShake} from 'react-animations';
import Radium, {StyleRoot} from 'radium';
import Carousel from 'react-bootstrap/Carousel';
import '../css/flyer.css';

function TransitionUp(props) {
    return <Slide {...props} direction="up"/>;
}

export class SignIn extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userToken: [],
            email: '',
            password: '',
            redirects: false,
            loading: false,
            hidden: true,
            open: false,
            openprogress: false,
            setOpenProgress: false,
            errors_signin: '',
            stylechange: [],
            permissions: [],
            login_description: ''
        };
        this.login = this.login.bind(this);
        this.onChange = this.onChange.bind(this);
        this.handleCloseProgress = this.handleCloseProgress.bind(this);
        this.handleToggleProgress = this.handleToggleProgress.bind(this);
        this.getCustomerData = this.getCustomerData.bind(this);
        this.refreshToken = this.refreshToken.bind(this);
        this.handleValidation = this.handleValidation.bind(this);
        this.toggleShow = this.toggleShow.bind(this);
        this.getImage = this.getImage.bind(this);
        this.getRolePermissions = this.getRolePermissions.bind(this);
    }

    getRolePermissions(laravel_token, admin_status, role) {


        if (admin_status == false) {

            let role_id = role;

            let itemsArr = [];


            this.handleToggleProgress();

            let BaseUrl = myConfig.laravelUrl;
            let lar_config = {
                headers: {
                    'content-type': 'multipart/form-data',
                    'Authorization': laravel_token,

                },
            };

            return axios.get(BaseUrl + "user_roles/get_single_role/" + sessionStorage.getItem("merchant_id") + '|' + sessionStorage.getItem("encrypted_merchant_id") + '|' + role_id, lar_config)
                .then(response => {
                    this.handleCloseProgress();
                    sessionStorage.setItem("permissions", JSON.stringify(response.data.permissions))
                    /*  this.setState({
                          permissions: response.data.permissions,
                      }, () => {

                      });*/
                })
                .catch(error => {
                    /*         if (error.response){
                                 if (error.response.status==401){
                                     sessionStorage.removeItem('userToken');
                                     localStorage.removeItem('userToken');
                                     sessionStorage.removeItem('laravel_token');
                                     localStorage.removeItem('laravel_token');
                                     localStorage.removeItem('merchant_id');
                                     sessionStorage.removeItem('merchant_id');
                                     window.location.reload();
                                 }
                             }*/

                });

            /*  GetWebxLaravel("user_roles/get_single_role/" + sessionStorage.getItem("merchant_id") + '|' + sessionStorage.getItem("encrypted_merchant_id")+'|'+role_id).then(response => {

              });*/
        }
    }

    toggleShow() {
        this.setState({hidden: !this.state.hidden});
    }

    handleValidation() {

        let errors_signin = {};
        let formIsValid = true;
        errors_signin["email"] = '';
        errors_signin["password"] = '';
        if (this.state.email == "") {
            formIsValid = false;
            errors_signin["email"] = "Email is required";
        }

        const styles = {
            bounce: {
                animation: 'x 1s',
                animationName: Radium.keyframes(headShake, 'headShake')
            }
        }
        if (this.state.password == "") {
            formIsValid = false;
            errors_signin["password"] = "Password is required";
        }

        this.setState({
            errors_signin: errors_signin,
            stylechange: styles.bounce
        });
        return formIsValid;
    }

    getImage(laravel_token) {
        this.handleToggleProgress();

        let BaseUrl = myConfig.laravelUrl;
        let lar_config = {
            headers: {
                'content-type': 'multipart/form-data',
                'Authorization': laravel_token,

            },
        };

        return axios.get(BaseUrl + "merchants/get_image/" + sessionStorage.getItem("merchant_id") + '|' + sessionStorage.getItem("encrypted_merchant_id"), lar_config)
            .then(response => {
                this.handleCloseProgress();
                sessionStorage.setItem("logo_image", response.data)
                /*      console.log(response.data)
                      this.setState({
                          logo_file:response.data!==null?response.data:require('../images/wbx_logo.png'),
                      });*/
            })
            .catch(error => {
                /*         if (error.response){
                             if (error.response.status==401){
                                 sessionStorage.removeItem('userToken');
                                 localStorage.removeItem('userToken');
                                 sessionStorage.removeItem('laravel_token');
                                 localStorage.removeItem('laravel_token');
                                 localStorage.removeItem('merchant_id');
                                 sessionStorage.removeItem('merchant_id');
                                 window.location.reload();
                             }
                         }*/

            });

    }

    handleCloseProgress = () => {
        this.setState({setOpenProgress: false});
        this.setState({openprogress: false});
    };
    handleToggleProgress = () => {
        this.setState({setOpenProgress: true});
        this.setState({openprogress: true});
    };

    handleClick = Transition => () => {
        this.setState({open: true, Transition});
    };

    handle(Transition) {
        this.setState({open: true, Transition});
    }

    handleClose = () => {
        this.setState({open: false});
    };

    toggleShow() {
        this.setState({hidden: !this.state.hidden});
    }

    /*   login(e) {
           if (this.handleValidation()){
           this.setState({loading: true});
           e.preventDefault();
               this.handleToggleProgress();
           if (this.state.email && this.state.password) {

               let BaseUrl = myConfig.apiUrl;
               let formData = new FormData();    //formdata object
               let email = this.state.email;    //formdata object
               let password = this.state.password;    //formdata object

               formData.append('email', email);   //append the values with key, value pair
               formData.append('password', password);

               const config = {
                   headers: {
                       'content-type': 'multipart/form-data',
                   }
               };

               axios.post(BaseUrl + "loginRequest", formData, config)

                   .then(response => {
                       console.log(response.data);
                       this.handleCloseProgress();
                       if (response.data.status == "Success") {

                           this.storeAndLoginLaravel(response.data.token, email, password, response.data.store_id);
                       } else {
                           this.handle(TransitionUp);
                       }
                   })
                   .catch(error => {
                       console.log(error);
                   });

           }
           }
       }*/
    login() {

        if (this.handleValidation()) {
            this.handleToggleProgress();
            let BaseUrl = myConfig.redirectUrl;
            let formData = new FormData();    //formdata object
            let email = this.state.email;    //formdata object
            let password = this.state.password;    //formdata object
            sessionStorage.setItem("user_email", this.state.email)
            formData.append('email', email);   //append the values with key, value pair
            formData.append('password', password);
            //   formData.append('token', token);   //append the values with key, value pair
            //   formData.append('merchant_id', store_id);   //append the values with key, value pair

            axios.post(BaseUrl + "users/create_user", formData)
                .then(response => {

                    if (response.data.success == 202) {
                        window.location.href = myConfig.laravelWeb + 'merchant_login/' + response.data.credential;
                    } else if (response.data.success == 220) {
                           window.location.href = response.data.message;
                    } else if (response.data.success == 250) {
                        // qr only =1 , qr with link =2 , ipg enabled =3

                        sessionStorage.setItem('password_change', response.data.password_change);
                        sessionStorage.setItem('new_password', true);
                        sessionStorage.setItem("change_password", false);
                        sessionStorage.setItem('userToken', response.data.login_data.token);
                        localStorage.setItem('userToken', response.data.login_data.token);

                        sessionStorage.setItem('merchant_id', response.data.login_data.merchant_id);
                        localStorage.setItem('merchant_id', response.data.login_data.merchant_id);

                        sessionStorage.setItem('encrypted_merchant_id', response.data.login_data.encrypted_data);
                        localStorage.setItem('encrypted_merchant_id', response.data.login_data.encrypted_data);






                        this.getImage('Bearer ' + response.data.access_token)
                        sessionStorage.setItem('laravel_token', 'Bearer ' + response.data.access_token);
                        sessionStorage.setItem('admin_status', response.data.admin);
                        sessionStorage.setItem('user_name', response.data.login_data.first_name + ' ' + response.data.login_data.last_name);
                        sessionStorage.setItem('sms_enabled', response.data.sms_enabled);
                        sessionStorage.setItem('emi_enabled', response.data.emi_enabled);

                        this.getRolePermissions('Bearer ' + response.data.access_token, response.data.admin, response.data.role);

                        localStorage.setItem('laravel_token', 'Bearer ' + response.data.access_token);



                        setInterval(this.refreshToken, 2000);

                        this.setState({redirects: true});

                       // this.props.history.push('/change-password');
                    } else {
                        if (response.data.success !== 400) {


                            //this.getCustomerData(response.data.login_data.token);
                            sessionStorage.setItem('userToken', response.data.login_data.token);
                            localStorage.setItem('userToken', response.data.login_data.token);

                            sessionStorage.setItem('merchant_id', response.data.login_data.merchant_id);
                            localStorage.setItem('merchant_id', response.data.login_data.merchant_id);

                            sessionStorage.setItem('encrypted_merchant_id', response.data.login_data.encrypted_data);
                            localStorage.setItem('encrypted_merchant_id', response.data.login_data.encrypted_data);
                            sessionStorage.setItem('password_change', response.data.password_change);

                            sessionStorage.setItem('new_password', false);

                            this.getImage('Bearer ' + response.data.access_token)
                            sessionStorage.setItem('laravel_token', 'Bearer ' + response.data.access_token);
                            sessionStorage.setItem('admin_status', response.data.admin);
                            sessionStorage.setItem('user_name', response.data.login_data.first_name + ' ' + response.data.login_data.last_name);
                            sessionStorage.setItem('sms_enabled', response.data.sms_enabled);
                            sessionStorage.setItem('emi_enabled', response.data.emi_enabled);

                            this.getRolePermissions('Bearer ' + response.data.access_token, response.data.admin, response.data.role);

                            localStorage.setItem('laravel_token', 'Bearer ' + response.data.access_token);
                            setInterval(this.refreshToken, 2000);
                            this.setState({redirects: true});
                        } else {
                            this.handleCloseProgress();
                            this.handle(TransitionUp);
                            this.setState({
                                login_description: response.data.message
                            })
                        }
                    }
                })
                .catch(error => {
                    console.log(error);
                });

        }
    }

    refreshToken() {

    }

    getCustomerData(userToken) {
        this.handleToggleProgress();
        let BaseUrl = myConfig.laravelUrl;
        let formData = new FormData();    //formdata object


        formData.append('token', userToken);   //append the values with key, value pair


        axios.post(BaseUrl + "getStoreData", formData)
            .then(response => {

                sessionStorage.setItem("doing_business_name", response.data[0].doing_business_name);
                sessionStorage.setItem("merchant_number", response.data[0].merchant_number);
                sessionStorage.setItem("bpsp_status", response.data[0].bpsp_status);
                sessionStorage.setItem("bpsp_mdr_rate", response.data[0].bpsp_mdr_rate);
                sessionStorage.setItem("qr_enable", response.data[0].allowed_transaction_type);

            })
            .catch(error => {
                console.log(error);
            });


    }

    onChange(e) {

        this.setState({
            [e.target.name]: e.target.value
        })
    }

    render() {
        if (sessionStorage.getItem("userToken") || localStorage.getItem("userToken")) {
            return <Navigate to='/active-dashboard'/>
           /* if (sessionStorage.getItem('change_password') == true) {
                return <Navigate to='/change-password'/>
            } else {

            }*/
        }


        if (this.state.redirects) {
            return <Navigate to='/active-dashboard'/>
        }


        return (
            <div>

                <Backdrop className="backdrop-loading" open={this.state.openprogress}>
                    <CircularProgress color="inherit"/>
                </Backdrop>
                <div id="outer-container" className="container-fluid login-outer-container">
                    <div id="turn-box"></div>
                    <div id="inside-container" className="container">
                        <div className="row row_clr main-row banner-mr">
                            <div className="col-sm-6 logo-txt">
                                <Carousel id="webx-signup-carousel">
                                    <Carousel.Item interval={5000}>
                                        <img src={require('../images/leaflets/banner1.png')} alt="money"/>
                                    </Carousel.Item>
                                    <Carousel.Item interval={5000}>
                                        <img src={require('../images/leaflets/banner2.png')} alt="money"/>
                                    </Carousel.Item>
                                    <Carousel.Item interval={5000}>
                                        <img src={require('../images/leaflets/banner3.png')} alt="money"/>
                                    </Carousel.Item>
                                    {/*<Carousel.Item interval={5000}>*/}
                                    {/*    <img src={require('../images/leaflets/banner4.png')} alt="money"/>*/}
                                    {/*</Carousel.Item>*/}
                                </Carousel>
                            </div>

                            <div className="col sign-box">
                                <div className="row">
                                    <div className="col-12 sign-top">
                                        <img src={require('../images/sign-in-icon.svg')} alt="sign-in-icon"
                                             className="sign-icon"/>
                                        <h2 className="sign-head">Sign In</h2>
                                    </div>
                                    <div className="col-12 form-type-1">

                                        <form className="row row_clr">
                                            <div className="col-12 p-0">
                                                <div className="form-group"><input type="text" className="form-control"
                                                                                   name="email"
                                                                                   id="u-name"
                                                                                   placeholder="Enter Email address"
                                                                                   onChange={this.onChange}/>
                                                    <StyleRoot style={this.state.stylechange}>
                                                        <span className="error"
                                                              style={{color: "red"}}>{this.state.errors_signin['email']}</span>
                                                    </StyleRoot>

                                                </div>

                                                <div className="form-group">
                                                    <input type={this.state.hidden ? "password" : "text"}
                                                           className="form-control password" id="password"
                                                           name="password"
                                                           placeholder="Enter Password" onKeyPress={event => {
                                                        if (event.key === 'Enter') {
                                                            this.login(event)
                                                        }
                                                    }}
                                                           onChange={this.onChange}/>
                                                    <StyleRoot style={this.state.stylechange}>
                                                        <span className="error"
                                                              style={{color: "red"}}>{this.state.errors_signin['password']}</span>
                                                    </StyleRoot>

                                                    <button type='button' id="password-txt" className="password-txt"
                                                            onClick={this.toggleShow}>{this.state.hidden ?
                                                        <img src={require('../images/visibility-orange.svg')}
                                                             alt="visibility"/> :
                                                        <img src={require('../images/visibility.svg')}
                                                             alt="visibility"/>}</button>
                                                </div>
                                            </div>
                                            <div className="col-6 p-0 cbox">
                                                {/* <label className="cont">
                                                <input type="checkbox" checked="" />
                                                    <span className="checkmark"></span>
                                                    Remember Me
                                            </label>*/}
                                            </div>
                                            <div className="col-6 p-0 fogp">
                                                <Link to="../forgot-password" className="fp">Forgot Password?</Link>
                                            </div>
                                            <div className="col-12 no-pad submit">
                                                <input type="button" id="submit-btn" value="Sign In"
                                                       onClick={this.login}/>
                                            </div>
                                        </form>

                                    </div>
                                    <div className="col-12 no-account">
                                        {/*  <p className="no-account-p">Don't have an account?  <Link className="caccount" to="../sign-up">Create an
                                        Account</Link></p>*/}
                                    </div>
                                    <div className="col-12 copy-txt">
                                        <p className="cop-p">By Signing In or Registering on the system, you confirm
                                            that
                                            you agree to our <a href="#" className="green-txt">Terms of
                                                Service</a> and <a
                                                href="https://webxpay.co/index.php/privacy-policy/" target="_blank"
                                                className="green-txt">Privacy Policy</a>.</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <Snackbar
                        open={this.state.open}
                        onClose={this.handleClose}
                        TransitionComponent={this.state.Transition}
                        ContentProps={{
                            'aria-describedby': 'message-id',
                        }}
                        autoHideDuration={3500}
                        message={<span id="message-id">{this.state.login_description}</span>}
                    />
                </div>
            </div>
        );
    }

}