import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Link, Navigate} from "react-router-dom";
import NumberFormat from "react-number-format";
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import {Navigation} from "./Navigation";
import ReactPaginate from 'react-paginate';
import {myConfig} from "../config";
import {TopBar} from "./TopBar";
import Modal from "react-bootstrap/Modal";
import {CSVLink} from "react-csv";
import {changeNumber, GetWebxLaravel, PostCompnentWebx, PostWebxLaravel} from "./PostCompnent";
import Dropdown from "react-bootstrap/Dropdown";
import {Footer} from "./Footer";
import LoadingSpinner from "./LoadingSpinner";
import {SupportButton} from "./SupportButton";
import {exportPDFDownload} from "./ExportPdf";


export class Transections extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            link_description: '',
            order_ref_number: '',
            status: '',
            transaction_value: '',
            startDate: moment().subtract(30, 'days').format('ll'),
            endDate: moment().format('ll'),
            transaction_lists: [],
            transaction_lists_render: [],
            lkr_transaction_total: 0,
            lkr_refund_total: 0,
            usd_refund_total: 0,
            gbp_refund_total: 0,
            usd_transaction_total: 0,
            gbp_transaction_total: 0,
            offset: 0,
            perPage: 10,
            currentPage: 0,
            transaction_lists_filters: [],
            modalShow: false,
            setModalShow: false,
            transaction_individual_data: [],
            dayCount: 31,
            csvData: [],
            base_image: '',
            transactionModelOpen: false,
            setTransactionModelOpen: false,
            transaction_reference: '',
            refund_amount: '',
            total_amount: '',
            refund_reason: '',
            store_reference: '',
            transaction_currency: '',
            refund_processing_currency: '',
            errors_refund: [],
            pending_search: true,
            modalShowCloseConfirmation: false,
            setModalShowCloseConfimation: false,
            refund_password: '',
            refund_message: '',
            point_of_transaction: '',
            refund_reason_status: '',
            payment_type: '',
            loading: false,
            startDateCsv: moment().subtract(30, 'days').format('ll'),
            endDateCsv: moment().format('ll'),
            payment_customer: '',

        };

        this.fetchDataFirst = this.fetchDataFirst.bind(this);
        this.handleCallback = this.handleCallback.bind(this);
        this.loadTable = this.loadTable.bind(this);
        this.filterList = this.filterList.bind(this);
        this.clearSearch = this.clearSearch.bind(this);
        this.onChanges = this.onChanges.bind(this);
        this.ModalCheckTrans = this.ModalCheckTrans.bind(this);
        this.modalClose = this.modalClose.bind(this);
        this.exportPDF = this.exportPDF.bind(this);
        this.showModalConfirmation = this.showModalConfirmation.bind(this);
        this.closeModalConfirmation = this.closeModalConfirmation.bind(this);
        this.approveTransaction = this.approveTransaction.bind(this);
        this.declineTransaction = this.declineTransaction.bind(this);
        this.openModalRefundConfirm = this.openModalRefundConfirm.bind(this);
        this.closeModalRefundConfirm = this.closeModalRefundConfirm.bind(this);
        this.findDate = this.findDate.bind(this);
        this.onChangesRefund = this.onChangesRefund.bind(this);
        this.handlePageClick = this.handlePageClick.bind(this);
        this.renderCurrency=this.renderCurrency.bind(this);
        this.getCurrencyPrefix=this.getCurrencyPrefix.bind(this);
        this.callRedirect=this.callRedirect.bind(this);
        this.getTransactionCount=this.getTransactionCount.bind(this);
    }

    getTransactionCount= () =>{
        let formData = new FormData();    //formdata object
        formData.append('merchant_id', sessionStorage.getItem("merchant_id"));
        PostWebxLaravel("links/get-transaction-count",formData).then(response=>{
            this.setState({
                approved_count: response.data.approved_transactions,
                pending_count: response.data.pending_transactions,
            })

        });
    }

    handlePageClick(e) {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;
        this.setState({
            currentPage: selectedPage,
            offset: offset
        }, () => {
            this.loadTable()
        });

    };

    getCurrencyPrefix(id) {
        console.log(id)
        switch (id) {
            case 5:
                return 'LKR ';
            case 1:
                return 'GBP ';
            case 6:
                return 'AUD ';
            default:
                return 'USD ';
        }
    }

    renderCurrency(currency_id,total_sum,negetive=false){

        if (negetive==true){
            return (

                    <NumberFormat
                        value={total_sum}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'- '+this.getCurrencyPrefix(currency_id)}
                    />

            );
        }else {
            return (

                <NumberFormat
                    value={total_sum}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={this.getCurrencyPrefix(currency_id)}
                />

            );
        }
    }


    findDate() {
        var array = ["01-03-2021", "02-03-2021", "03-03-2021"];

        let new_date = moment('23-02-2021', 'DD-MM-YYYY').add(1, 'weeks').startOf('isoWeek').format('DD-MM-YYYY');

        for (let i = 0; i < array.length; i++) {
            var d1 = new Date(new_date);
            var d2 = new Date(array[i]);
            var same = d1.getTime() === d2.getTime();
        }
    }

    showModalConfirmation(transaction_reference, total_amount, processing_currency) {
        this.setState({
            transaction_reference: transaction_reference,
            total_amount: total_amount,
            refund_processing_currency: processing_currency,
            transactionModelOpen: true,
            setTransactionModelOpen: true,
            modalShow: false,
            setModalShow: false
        }, () => {
            var modelD = document.getElementById("anis-modal");
            modelD.classList.add("ancd-mod");
        });
    }

    closeModalConfirmation() {
        this.setState({
            transactionModelOpen: false,
            setTransactionModelOpen: false,
        });
    }

    openModalRefundConfirm() {
        this.clearSearch();
        this.setState({
            modalShowCloseConfirmation: true,
            setModalShowCloseConfimation: true,
        });

    }

    closeModalRefundConfirm() {
        this.setState({
            modalShowCloseConfirmation: false,
            setModalShowCloseConfimation: false,
            //modalShow: true,
            // setModalShow: true
        });
    }

    approveTransaction() {
        if (this.handleValidation()) {
            this.handleToggleProgress();
            this.setState({loading: true});
            let formData = new FormData();    //formdata object
            formData.append('order_refference_number', this.state.transaction_reference);   //append the values with key, value pair
            formData.append('refund_amount', this.state.refund_amount);
            formData.append('refund_reason_id', this.state.refund_reason);
            formData.append('refund_reason_other', this.state.refund_reason_other);
            formData.append('user_password', this.state.refund_password);
            PostCompnentWebx("requestRefundByOrderReference", formData).then(response => {
                this.handleCloseProgress();
                this.closeModalConfirmation();

                this.setState({
                    refund_amount: '',
                    loading: false,
                    refund_reason: '',
                    refund_reason_other: '',
                    refund_password: '',
                    refund_message: response.data.explaination
                }, () => {
                    this.openModalRefundConfirm();

                })

            });
        }
    }

    declineTransaction() {

    }

    filterList() {
        this.handleToggleProgress();
        let order_ref_no = this.state.order_ref_number.toLowerCase();
        let status = this.state.status.toString();
        let transaction_value = this.state.transaction_value.toLowerCase();
        let transaction_currency = this.state.transaction_currency;
        let store_reference = this.state.store_reference;
        let payment_type = this.state.payment_type;
        let payment_customer = this.state.payment_customer;
        let transaction_lists = this.state.transaction_lists, transaction_lists_filters = [];


        let startDate = moment(this.state.startDate).format('YYYY-MM-DD');
        let endDate = moment(this.state.endDate).format('YYYY-MM-DD');
        if (this.state.startDate == "") {
            startDate = moment().subtract(30, 'days').format('YYYY-MM-DD')
        }
        if (this.state.endDate == "") {
            endDate = moment().format('YYYY-MM-DD');
        }

        var a = moment(endDate);
        var b = moment(startDate);

        this.setState({
            startDateCsv: startDate,
            endDateCsv: endDate
        });


        let days_count = a.diff(b, 'days');   // =1
        days_count = parseInt(days_count) + parseInt(1);

        let formData = new FormData();    //formdata object
        formData.append('filter_date_start', startDate);
        formData.append('filter_date_end', endDate);

        formData.append('total_amount', transaction_value);   //append the values with key, value pair
        formData.append('order_refference_number', order_ref_no);   //append the values with key, value pair
        formData.append('payment_status_id', status);   //append the values with key, value pair
        formData.append('expand_view', true);   //append the values with key, value pair
        formData.append('processing_currency_id', transaction_currency);   //append the values with key, value pair
        formData.append('store_reference', store_reference);   //append the values with key, value pair
        formData.append('card_type', payment_type);   //append the values with key, value pair
        formData.append('payment_customer', payment_customer);   //append the values with key, value pair

        PostCompnentWebx("getStoreTransactions", formData).then(response => {
            this.handleCloseProgress();
            this.loadCsvDataTransaction(response);

            this.setState({
                transaction_lists: response.data,
                transaction_lists_filters: response.data,
                offset: 0,
                pending_search: true,
                dayCount: days_count,
            }, () => {
                this.loadTable()
            });

        });

        PostCompnentWebx("getStoreTransactionAndRefundTotal",formData).then(response=>{

            this.handleCloseProgress();
            this.setState({lkr_refund_total: response.data.totals.lkr_refund_total});
            this.setState({usd_refund_total: response.data.totals.usd_refund_total});
            this.setState({gbp_refund_total: response.data.totals.gbp_refund_total});
            this.setState({inr_refund_total: response.data.totals.inr_refund_total});
            this.setState({aud_refund_total: response.data.totals.aud_refund_total});

            this.setState({lkr_transaction_total: response.data.totals.lkr_transaction_total});
            this.setState({usd_transaction_total: response.data.totals.usd_transaction_total});
            this.setState({inr_transaction_total: response.data.totals.inr_transaction_total});
            this.setState({gbp_transaction_total: response.data.totals.gbp_transaction_total});
            this.setState({aud_transaction_total: response.data.totals.aud_transaction_total});
        });

    }

    clearSearch() {

        //this.props.history.push('/transactions');
        //  window.location.reload();
        this.handleToggleProgress();
        let formData = new FormData();    //formdata object
        let startDate = moment(this.state.startDate).format('YYYY-MM-DD');
        let endDate = moment(this.state.endDate).format('YYYY-MM-DD');

        formData.append('filter_date_start', startDate);
        formData.append('filter_date_end', endDate);
        formData.append('expand_view', true);   //append the values with key, value pair
        var a = moment(endDate);
        var b = moment(startDate);
        let days_count = a.diff(b, 'days');   // =1
        days_count = parseInt(days_count) + parseInt(1);
        PostCompnentWebx("getStoreTransactions", formData).then(response => {
            this.handleCloseProgress();
            this.loadCsvDataTransaction(response);
            var second = document.querySelector(".pagination > li:nth-child(2) > a");
            if (second !== null) {
                second.click();
            }

            this.setState({
                transaction_lists: response.data,
                transaction_lists_filters: response.data,
                pending_search: true,
                dayCount: days_count,
                offset: 0,
                perPage: 10,
                currentPage: 1,
                order_ref_number: '',
                status: '',
                transaction_value: '',
                payment_customer: '',
                payment_type: '',
                transaction_currency: '',
                store_reference: '',
                initialPage: 1,
            }, () => {
                this.loadTable();

            });
        });

    }

    handleCallback(start, end, label) {
        this.setState({
            startDate: moment(start).format('ll'),
            endDate: moment(end).format('ll'),
        });

        this.filterList();
    }

    fetchDataFirst(end, start) {

        this.handleToggleProgress();

        var userToken = sessionStorage.getItem("userToken") || localStorage.getItem("userToken");
        if (end && start) {
            let BaseUrl = myConfig.apiUrl;
            let formData = new FormData();    //formdata object

            let startd = moment(start).format('YYYY-MM-DD');

            let endd = moment(end).format('YYYY-MM-DD');
            var a = moment(end);
            var b = moment(start);
            let days_count = a.diff(b, 'days');   // =1
            days_count = parseInt(days_count) + parseInt(1);
            formData.append('expand_view', true);   //append the values with key, value pair
            formData.append('filter_date_start', startd);
            formData.append('filter_date_end', endd);

            PostCompnentWebx("getStoreTransactions", formData).then(response => {
                this.loadCsvDataTransaction(response);

                this.setState({
                    transaction_lists: response.data,
                    transaction_lists_filters: response.data,
                    offset: 0,
                    dayCount: days_count
                }, () => {
                    this.loadTable()
                });

            });

            PostCompnentWebx("getStoreTransactionAndRefundTotal", formData).then(response => {
                this.handleCloseProgress();
                this.setState({lkr_refund_total: response.data.totals.lkr_refund_total});
                this.setState({usd_refund_total: response.data.totals.usd_refund_total});
                this.setState({gbp_refund_total: response.data.totals.gbp_refund_total});
                this.setState({inr_refund_total: response.data.totals.inr_refund_total});
                this.setState({aud_refund_total: response.data.totals.aud_refund_total});

                this.setState({lkr_transaction_total: response.data.totals.lkr_transaction_total});
                this.setState({usd_transaction_total: response.data.totals.usd_transaction_total});
                this.setState({inr_transaction_total: response.data.totals.inr_transaction_total});
                this.setState({gbp_transaction_total: response.data.totals.gbp_transaction_total});
                this.setState({aud_transaction_total: response.data.totals.aud_transaction_total});
            });

        }
    }

    loadCsvDataTransaction(response) {
        const csvData = [];

        csvData.push([
            "", "", "TRANSACTION REPORT ", "" + moment(this.state.startDateCsv).format('DD-MM-YYYY') + ' - ' + moment(this.state.endDateCsv).format('DD-MM-YYYY')
        ]);
        csvData.push([]);

        csvData.push(["TRANSACTION DATE", "STORE ORDER ID", "CUSTOMER FIRST NAME", "CUSTOMER LAST NAME", "TRANSACTION ID", "PROCESSING CURRENCY", "ORDER AMOUNT", "CONVENIENCE FEE / DISCOUNT", "TRANSACTION AMOUNT", "GATEWAY DISCOUNT", "TRANSACTION NET",/* "SETTLEMENT CURRENCY", "SETTLEMENT AMOUNT",*/"PAYMENT GATEWAY", "CARD TYPE","MASKED CARD NUMBER", "PAYMENT STATUS"]);
        response.data.map((value, index) => {
            let payment_status = "";
            if (value.payment_status_id == 2) {
                payment_status = "Approved";
            } else if (value.payment_status_id == 3) {
                payment_status = "Declined";
            }
            csvData.push([moment(value.date_added).format('ll'), value.store_reference, value.first_name, value.last_name, value.order_refference_number, value.processing_currency_code, value.order_amount, value.convenience_fee, value.total_amount, value.discount_amount, value.transaction_net,value.payment_gateway, value.card_type,value.masked_card_number, payment_status]);
        });


        this.setState({
            csvData: csvData
        });
    }

    loadTable() {
        const list_data = this.state.transaction_lists_filters;
        const slice = list_data.slice(this.state.offset, this.state.offset + this.state.perPage);

        const trans = list_data && list_data.length ? (slice.map(list => list.payment_status_id !== 1 ? (
                <tr className=''>
                    <td className=''
                        data-title='Transaction ID'>{list.order_id}
                    </td>
                    <td className=''
                        data-title='Transaction ID'>{list.order_refference_number}
                    </td>
                    <td className=''
                        data-title='Store Reference'>{list.store_reference}
                    </td>

                    {/* <td className=''
                 data-title='Transaction ID'>{list.order_refference_number}
             </td>*/}
                    <td className='' data-title='Transaction Value'>
                        <NumberFormat
                            value={(Math.round(list.total_amount * 100) / 100).toFixed(2)}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={list.processing_currency_code+' '}
                        />

                    </td>
                    <td>
                        {
                            (() => {
                                let default_size = "35px";
                                let max_size = "63px";
                                if (list.payment_gateway == "FriMi (LKR)" || list.payment_gateway == "Genie Visa Master (LKR)" || list.payment_gateway == "UPay (LKR)") {
                                    default_size = "35px";

                                } else {
                                    default_size = max_size;
                                }
                                if (list.card_type) {
                                    if (list.card_type.toLowerCase() == "visa")
                                        if(list.tenure>0)
                                            return <img style={{width: default_size}}
                                                        src={myConfig.laravelWeb + 'gateway_logos/' + list.payment_gateway_id + '.png'}
                                                        alt=""/>;
                                        else
                                            return <img style={{width: "35px"}}
                                                        src={myConfig.laravelWeb + 'gateway_logos/visa.jpg'}
                                                        alt=""/>;

                                    if (list.card_type.toLowerCase() == "master" || list.card_type.toLowerCase() == "mastercard")
                                        return <img style={{width: "35px"}}
                                                    src={myConfig.laravelWeb + 'gateway_logos/master.jpg'} alt=""/>;
                                    else
                                        return <img style={{width: default_size}}
                                                    src={myConfig.laravelWeb + 'gateway_logos/' + list.payment_gateway_id + '.jpg'}
                                                    alt=""/>
                                }


                            })()
                        }
                    </td>

                    <td className='' data-title='Date of Transaction'>{moment(list.date_added).format('ll')}
                    </td>
                    <td className='pop-td' data-toggle="popover"
                        data-trigger="hover" data-placement="auto"
                        data-content="Bank was Unable to Authenticate Transaction">
                        {list.payment_status_id == 2 ? (<span className="dot green"><i className="fas fa-circle"
                                                                                       aria-hidden="true"></i></span>) : ''} {list.payment_status_id == 3 ? (
                        <span className="dot red"><i className="fas fa-circle"
                                                     aria-hidden="true"></i></span>) : ''} {list.payment_status_id == 1 ? "" : ''}{list.payment_status_id == 2 ? "Approved" : ''}{list.payment_status_id == 3 ? "Declined" : ''}
                    </td>
                    <td className='' data-title='View'>
                        <button className="dot-drop btn"
                                onClick={() => this.ModalCheckTrans(list.order_refference_number)}>
                            <i className="fas fa-eye"></i></button>
                    </td>
                </tr>
            ) : (this.state.pending_search == true ? (
                    <tr className=''>
                        <td className='' data-title='#'>{list.order_id}</td>
                        <td className=''
                            data-title='Transaction ID'>{list.order_refference_number}
                        </td>
                        {/* <td className=''
                        data-title='Transaction ID'>{list.order_refference_number}
                    </td>*/}
                        <td className='' data-title='Transaction Value'>
                            <NumberFormat
                                value={(Math.round(list.total_amount * 100) / 100).toFixed(2)}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={list.processing_currency_code}
                            />

                        </td>
                        <td className='' data-title='Date of Transaction'>{moment(list.date_added).format('ll')}
                        </td>
                        <td className='pop-td' data-toggle="popover"
                            data-trigger="hover" data-placement="auto"
                            data-content="Bank was Unable to Authenticate Transaction">

                            {list.payment_status_id == 2 ? (<span className="dot green"><i className="fas fa-circle"
                                                                                           aria-hidden="true"></i></span>) : ''}
                            {list.payment_status_id == 3 ? (
                                <span className="dot red"><i className="fas fa-circle" aria-hidden="true"></i></span>) : ''}
                            {list.payment_status_id == 2 ? "Approved" : ''}
                            {list.payment_status_id == 3 ? "Declined" : ''}

                        </td>
                        <td className='' data-title='View'>
                            <button
                                onClick={() => this.ModalCheckTrans(list.order_refference_number)}>
                                <i className="fas fa-eye"></i></button>
                        </td>
                    </tr>
                ) : (
                    <tr className=''>
                        <td colSpan="6" className='' data-title='#'><strong>No results found, for the selected date
                            range</strong></td>
                    </tr>
                )
            )
        )) : (
            <tr className=''>
                <td colSpan="8" className='' data-title='#'><strong>No results found, for the selected date
                    range</strong></td>
            </tr>

        );
        this.setState({
            pageCount: Math.ceil(list_data.length / this.state.perPage),
            transaction_lists_render: trans
        })
    }


    handleCloseProgress = () => {
        this.setState({setOpenProgress: false});
        this.setState({openprogress: false});
    };
    handleToggleProgress = () => {
        this.setState({setOpenProgress: true});
        this.setState({openprogress: true});
    };

    componentDidMount() {
        this.interval =
        this.findDate();
        if (sessionStorage.getItem("userToken") || localStorage.getItem("userToken")) {

            var userToken = sessionStorage.getItem("userToken") || localStorage.getItem("userToken");

            this.setState({userToken: userToken});
            this.getTransactionCount()
            this.fetchDataFirst(this.state.endDate, this.state.startDate);
       //     setInterval(() => this.callRedirect(this.state.endDate, this.state.startDate), 60000);


        } else {
            this.setState({redirect: true});
        }
    }

    componentWillMount() {
        clearInterval(this.interval);

    }

    callRedirect= (start_date,end_date) => {
        this.fetchDataFirst(start_date, end_date)
    }

    modalClose() {
        this.setState({
            modalShow: false,
            setModalShow: false
        })
    }


    ModalCheckTrans(transaction_id) {


        let formData = new FormData();    //formdata objec

        formData.append('order_refference_number', transaction_id);   //append the values with key, value pair

        this.handleToggleProgress();

        GetWebxLaravel("common_list/get_point_of_transaction/" + transaction_id).then(response => {
            let data_type = response.data.type;
            let point_of_transaction = '';
            let link_description = '';
            if (data_type === 0) {
                point_of_transaction = "X GATEWAY";
            } else if (data_type === 1) {
                point_of_transaction = "X DIRECT"
            } else if (data_type === 2) {
                point_of_transaction = "X SELL";
                link_description = response.data.reference_description;
            } else {
                point_of_transaction = "MY QR";
                link_description = response.data.reference_description;
            }
            this.setState({
                point_of_transaction: point_of_transaction,
                link_description: link_description
            });
        });

        PostCompnentWebx("getTransactionData", formData).then(response => {
            this.handleCloseProgress();
            this.setState({
                transaction_individual_data: response.data,
                modalShow: true,
                setModalShow: true
            });

        });

    }

    handleValidation() {
        let errors_refund = {};
        let formIsValid = true;
        //Email
        if (this.state.refund_amount == "") {
            formIsValid = false;
            errors_refund["refund_amount"] = "Cannot be empty";
        }
        if (this.state.refund_reason == "") {
            formIsValid = false;
            errors_refund["refund_reason"] = "Cannot be empty";
        }
        if (this.state.refund_reason == 5) {
            if (this.state.refund_reason_other == "") {
                formIsValid = false;
                errors_refund["refund_reason_other"] = "Cannot be empty";
            }
        }
        if (this.state.refund_password == "") {
            formIsValid = false;
            errors_refund["refund_password"] = "Cannot be empty";
        }

        this.setState({errors_refund: errors_refund});
        return formIsValid;
    }

    onChangesRefund(e) {
        let value = e.target.value;
        this.setState({
            refund_reason_other: ''
        });
        if (e.target.name == "refund_reason") {
            var index = e.nativeEvent.target.selectedIndex;
            if (value == "5") {
                var other_id = document.getElementById("other-id");
                other_id.classList.add("act");
                this.setState({
                    //refund_reason_other: '',
                    [e.target.name]: e.target.value
                });
            } else {
                var other_id = document.getElementById("other-id");
                other_id.classList.remove("act");
                this.setState({
                    refund_reason_other: e.nativeEvent.target[index].text,
                    [e.target.name]: e.target.value
                });
            }
        }
        if (e.target.name == "refund_reason_other") {
            this.setState({
                [e.target.name]: e.target.value
            });
        }
    }

    onChanges(e) {
        if (e.target.name == "refund_amount") {
            let new_val = changeNumber(e.target.value);

            this.setState({[e.target.name]: new_val});

            if (parseFloat(this.state.total_amount) < parseFloat(e.target.value)) {
                this.setState({
                    [e.target.name]: this.state.total_amount
                });
            }
        } else {
            this.setState({
                [e.target.name]: e.target.value
            });
        }
    }

    exportPDF = () => {
        let transections = this.state.transaction_lists_filters;

        const headers = [["TRANSACTION DATE", "STORE ORDER ID", "CUSTOMER FIRST NAME", "CUSTOMER LAST NAME",
            "TRANSACTION ID", "PROCESSING CURRENCY", "ORDER AMOUNT", "CONVENIENCE FEE / DISCOUNT", "TRANSACTION AMOUNT", "GATEWAY DISCOUNT",
            "TRANSACTION NET",/* "SETTLEMENT CURRENCY", "SETTLEMENT AMOUNT",*/"PAYMENT GATEWAY", "PAYMENT STATUS"]];
        let data = [];
        transections.map((value, index) => {
            let payment_status = "";
            if (value.payment_status_id == 2) {
                payment_status = "Approved";
            } else if (value.payment_status_id == 3) {
                payment_status = "Declined";
            }
            data.push([
                moment(value.date_added).format('ll'),
                value.store_reference,
                value.first_name,
                value.last_name,
                value.order_refference_number,
                value.processing_currency_id == 5 ? "LKR" : "USD",
                value.order_amount,
                value.convenience_fee,
                value.total_amount,
                value.discount_amount,
                value.transaction_net,
                value.payment_gateway,
                payment_status
            ]);
        });

        exportPDFDownload("TRANSACTION REPORT", headers, data, "Transactions.pdf");


    };

    render() {
        const transaction_lists = this.state.transaction_lists_render;
        const transaction_individual_data = this.state.transaction_individual_data;

        if (this.state.redirect) {
            return <Navigate to='/login'/>
        }
        return (
            <div>
                <SupportButton/>
                <Backdrop className="backdrop-loading" open={this.state.openprogress}>
                    <CircularProgress color="inherit"/>
                </Backdrop>
                <Modal
                    show={this.state.transactionModelOpen}
                    onHide={this.state.setTransactionModelOpen}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    id="anis-modal"
                    className="refund-modal"
                >
                    <Modal.Body className="modal-type-2">
                        <div>

                            <h1>Are you sure do you want to refund the amount?</h1>

                            <p><strong>Refund Reference No :</strong> {this.state.transaction_reference}</p>
                            <p className="lst"><strong>Amount :</strong><NumberFormat
                                value={(Math.round(this.state.total_amount * 100) / 100).toFixed(2)}
                                displayType={'text'} thousandSeparator={true}
                                prefix={this.state.refund_processing_currency+' '}/></p>
                            <p><strong>Enter Amount to Refund</strong></p>
                            <div className="form-group">
                                <input type="text" className="form-control" value={this.state.refund_amount}
                                       name="refund_amount" placeholder="Enter Refund Amount" id=""
                                       onChange={this.onChanges}/>
                                <span className="error"
                                      style={{color: "red"}}>{this.state.errors_refund["refund_amount"]}</span>
                            </div>
                            <p><strong>Refund Reason</strong></p>
                            {/*  <div className="form-group">
                                <input type="text" className="form-control" value={this.state.refund_reason}
                                       name="refund_reason" placeholder="Enter Refund Reason"
                                       onChange={this.onChanges}/>
                                <span className="error"
                                      style={{color: "red"}}>{this.state.errors_refund["refund_reason"]}</span>
                            </div>*/}
                            <div className="form-group">
                                <select id="refund_reason" className="form-control" value={this.state.refund_reason}
                                        name="refund_reason" placeholder="Enter Refund Reason"
                                        onChange={this.onChangesRefund}>
                                    <option value="">Select Reason</option>
                                    <option value="1">Customer Request</option>
                                    <option value="2">Customer Cancelled</option>
                                    <option value="3">Product Damage</option>
                                    <option value="4">Late Delivery</option>
                                    <option value="5">Other</option>
                                </select>
                                <span className="error"
                                      style={{color: "red"}}>{this.state.errors_refund["refund_reason"]}</span>

                            </div>
                            <div className="form-group" id="other-id">
                                <input type="text" className="form-control" value={this.state.refund_reason_other}
                                       name="refund_reason_other" placeholder="Enter Other Reason"
                                       onChange={this.onChangesRefund}/>
                                <span className="error"
                                      style={{color: "red"}}>{this.state.errors_refund["refund_reason_other"]}</span>
                            </div>

                            <p><strong>Enter Login Password</strong></p>
                            <div className="form-group">
                                <input type="password" className="form-control" value={this.state.refund_password}
                                       name="refund_password" placeholder="Enter Login Password"
                                       onChange={this.onChanges}/>
                                <span className="error"
                                      style={{color: "red"}}>{this.state.errors_refund["refund_password"]}</span>
                            </div>


                            <button className="btn btn-danger" onClick={this.closeModalConfirmation}>Cancel</button>
                            {this.state.loading ? <LoadingSpinner/> :
                                <button className="btn btn-success" onClick={this.approveTransaction}>Submit</button>}
                        </div>
                    </Modal.Body>

                </Modal>
                <Modal
                    show={this.state.modalShow}
                    onHide={this.state.setModalShow}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    id="activation-modal"
                >
                    <div id="divcontents">
                        <Modal.Body className="modal-type-2">
                            <div className="colse-mdl" data-dismiss="modal" aria-label="Close"
                                 onClick={this.modalClose}>X
                            </div>
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-4 lft">
                                        <h3 className="tdh">Transaction Details</h3>

                                        <div className="col-12 tdm-in">

                                            <h6>Transaction Amount</h6>
                                            <h3 className="tdh">

                                                <NumberFormat
                                                    value={(Math.round(transaction_individual_data.total_amount * 100) / 100).toFixed(2)}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    prefix={transaction_individual_data.processing_currency_code+' '}
                                                />
                                            </h3>

                                            <h6>Date of Transaction</h6>
                                            <h5 className="sh">{moment(transaction_individual_data.date_time_transaction).format("ll")}</h5>

                                            <h6>Transaction ID</h6>
                                            <h5 className="sh">{transaction_individual_data.order_refference_number}</h5>
                                            <h6>Store Reference</h6>
                                            <h5 className="sh">{transaction_individual_data.gateway_order_id}</h5>
                                            <h6>Clearance Status</h6>
                                            <h5 className="sh">
                                                {transaction_individual_data.clearance_status == "Settlement Pending" ? (
                                                    <span className="dot yellow"><i className="fas fa-circle"
                                                                                    aria-hidden="true"></i></span>) : ('')}
                                                {transaction_individual_data.clearance_status == "Settled" ? (
                                                    <span className="dot green"><i className="fas fa-circle"
                                                                                   aria-hidden="true"></i></span>) : ('')} {transaction_individual_data.clearance_status == "No Settlement" ? (<>
                                                <span style={{color: "red"}}>Payment Declined</span> < p className="sp"
                                                                                                         style={{
                                                                                                             fontSize: 12,
                                                                                                             fontWeight: 600,
                                                                                                             color: "#dc5937",
                                                                                                             padding: "10px 0 0 0"
                                                                                                         }}> {transaction_individual_data.response_text}</p></>) : transaction_individual_data.clearance_status}
                                            </h5>
                                            {transaction_individual_data.card_type && transaction_individual_data.card_type.toLowerCase() == "visa"?(
                                                    <>
                                           {/* <h6>Payment Gateway</h6>
                                            <h5 className="sh">{transaction_individual_data.payment_gateway}</h5>*/}

                                                    </>
                                            ):('')}
                                            <h6>Point of Transaction</h6>
                                            <h5 className="sh">{this.state.point_of_transaction}</h5>

                                            <h6>Payment Type</h6>
                                            <h5 className="sh">


                                                {transaction_individual_data.card_type?(
                                <>
                                                {transaction_individual_data.card_type.toLowerCase() == "visa" ? (
                                                    <img style={{width: "35px"}}
                                                         src={myConfig.laravelWeb + 'gateway_logos/visa.jpg'}
                                                         alt=""/>) : (<>
                                                    {transaction_individual_data.card_type.toLowerCase() == "master" || transaction_individual_data.card_type.toLowerCase() == "mastercard" ? (
                                                            <img style={{width: "35px"}}
                                                                 src={myConfig.laravelWeb + 'gateway_logos/master.jpg'} alt=""/>) : ( <img style={{width: "63px"}}
                                                                                                                                           src={myConfig.laravelWeb + 'gateway_logos/' + transaction_individual_data.payment_gateway_id + '.jpg'}
                                                                                                                                           alt=""/>
                                                        )}
                                                </>)}



                                                   </> ):('')}




                                                 {" "+transaction_individual_data.masked_card_number}</h5>

                                  {/*          {transaction_individual_data.card_type == "Visa" || transaction_individual_data.card_type == "Master" || transaction_individual_data.card_type == "Mastercard" ? (<>
                                                <h6>Card Type</h6><h5
                                                className="sh">{transaction_individual_data.card_type}</h5></>) : ('')}*/}
                                            {/*   <h6>Notes</h6>
                                        <h5 className="sh">REF 12323/1223</h5>*/}
                                            {this.state.link_description !== "" ? (
                                                <>
                                                    <h6>Description</h6>
                                                    <h5 className="sh">{this.state.link_description}</h5></>) : ('')
                                            }
                                        </div>

                                        {/*<a href={myConfig.laravelWeb + "reports/print_transaction/" + transaction_individual_data.order_refference_number + '/' + sessionStorage.getItem("userToken") || localStorage.getItem("userToken")}
                                           type="button" className="nxt-btn anb" target="_blank">Print Receipt</a>*/}
                                        {transaction_individual_data.payment_status_id == 2 ? (
                                            transaction_individual_data.order_status_id == 17 || transaction_individual_data.order_status_id == 11 ? ('') : (
                                                <button type="button" className="ext-btn ext2"
                                                        onClick={() => this.showModalConfirmation(transaction_individual_data.order_refference_number, transaction_individual_data.total_amount, transaction_individual_data.processing_currency_code)}>Refund
                                                </button>)
                                        ) : ('')}
                                        {transaction_individual_data.order_status_id == 17 ? (
                                            <button type="button" className="ext-btn ext2" disabled style={{
                                                backgroundColor: "#cccccc",
                                                color: "#000000",
                                                borderColor: "#000000"
                                            }}>Refund Requested</button>) : ''}
                                        {transaction_individual_data.order_status_id == 11 ?
                                            <button type="button" className="ext-btn ext2" disabled style={{
                                                backgroundColor: "#cccccc",
                                                color: "#000000",
                                                borderColor: "#000000"
                                            }}>Refunded</button> : ''}
                                        {/*   <a href="#" className="help"><i className="far fa-life-ring"
                                                                    aria-hidden="true"></i> Need a
                                        hand figuring it out?</a>*/}
                                    </div>
                                    <div className="col-md-8 rht">

                                        <h3 className="tdh">Transaction Timeline</h3>

                                        <div className="row">
                                            <div className="col-sm-3 col-12 tt-icns">
                                                <div className="icon-box">
                                                    <img src={require('../images/transactions/money.svg')} alt="money"/>
                                                </div>
                                                <p>Payment Initiated</p>
                                                <p className="sp">{moment(transaction_individual_data.date_time_transaction).format('ll')}</p>
                                            </div>

                                            {transaction_individual_data.clearance_status_id !== "33" ? (
                                                <React.Fragment>

                                                    <div className="col-sm-3 col-12 tt-icns">
                                                        <div className="icon-box">
                                                            <img src={require('../images/transactions/invoice.svg')}
                                                                 alt="money"/>
                                                        </div>
                                                        <p>Payment Successful</p>
                                                        <p className="sp"></p>
                                                    </div>
                                                    {transaction_individual_data.clearance_status_id == "22" ?
                                                        (
                                                            <div className="col-sm-3 col-12 tt-icns">
                                                                <div className="icon-box">
                                                                    <img
                                                                        src={require('../images/transactions/bank.svg')}
                                                                        alt="money"/>
                                                                </div>
                                                                <p>Settlement Completed</p>
                                                                <p className="sp">{moment(transaction_individual_data.settlement_date).format('ll')}</p>
                                                            </div>

                                                        ) : (
                                                            <>
                                                                <div className="col-sm-3 col-12 tt-icns">
                                                                    <div className="icon-box half-filled">
                                                                        <img
                                                                            src={require('../images/transactions/time-is-money.svg')}
                                                                            alt="money"/>
                                                                    </div>
                                                                    <p>{transaction_individual_data.clearance_status}</p>
                                                                    {transaction_individual_data.settlement_date ? transaction_individual_data.settlement_date !== "" ?
                                                                        (<p className="sp">Your Funds will be credited
                                                                            to your
                                                                            account
                                                                            on <span>{transaction_individual_data.settlement_date !== "" ? moment(transaction_individual_data.settlement_date).format('ll') : ''}</span>
                                                                        </p>) : ('') : ('')}

                                                                </div>
                                                            </>
                                                        )}
                                                </React.Fragment>
                                            ) : (
                                                <div className="col-sm-3 col-12 tt-icns">
                                                    <div className="icon-box">
                                                        <img
                                                            src={require('../images/transactions/payment_declined.svg')}
                                                            alt="money"/>
                                                    </div>
                                                    <p>Payment Declined</p>
                                                    <p className="sp">Sorry the payment has been declined</p>
                                                </div>
                                            )}
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-6 col-12 details-box">
                                                <div className="col-12 dbx-in">
                                                    <h3>Payee Details</h3>

                                                    <h6>Payee Name</h6>
                                                    <h5 className="sh">{transaction_individual_data.first_name} {transaction_individual_data.last_name}</h5>


                                                    <h6>Payee Telephone</h6>
                                                    <h5 className="sh">{transaction_individual_data.contact_number}</h5>

                                                    <h6>Payee E-Mail</h6>
                                                    <h5 className="sh">{transaction_individual_data.email_address}</h5>

                                                    <h6>Payee Address</h6>
                                                    <h5 className="sh">{transaction_individual_data.address_line_one}, {transaction_individual_data.address_line_two == "null" ? "" : transaction_individual_data.address_line_two + ', '} {transaction_individual_data.city}</h5>
                                                </div>
                                            </div>

                                            <div className="col-sm-6 col-12 details-box">
                                                <div className="col-12 dbx-in">
                                                    <h3>Payment Breakdown</h3>

                                                    <h6>Total Amount</h6>
                                                    <h5 className="sh">

                                                        <NumberFormat
                                                            value={(Math.round(transaction_individual_data.total_amount * 100) / 100).toFixed(2)}
                                                            displayType={'text'}
                                                            thousandSeparator={true}
                                                            prefix={transaction_individual_data.processing_currency_code+' '}
                                                        />
                                                    </h5>


                                                    <h6>WEBXPAY Charges</h6>
                                                    <h5 className="sh">

                                                        <NumberFormat
                                                            value={(Math.round(transaction_individual_data.discount_amount * 100) / 100).toFixed(2)}
                                                            displayType={'text'}
                                                            thousandSeparator={true}
                                                            prefix={transaction_individual_data.processing_currency_code+' '}
                                                        /> ({transaction_individual_data.payment_gateway_rate} %)
                                                    </h5>


                                                    <h6>Settlement Amount</h6>
                                                    <h5 className="sh">

                                                        <NumberFormat
                                                            value={(Math.round(transaction_individual_data.payment_amount * 100) / 100).toFixed(2)}
                                                            displayType={'text'}
                                                            thousandSeparator={true}
                                                            prefix={'- '+transaction_individual_data.payment_currency_code+' '}
                                                        />

                                                    </h5>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </Modal.Body>
                    </div>
                </Modal>

                <Modal
                    show={this.state.modalShowCloseConfirmation}
                    onHide={this.state.setModalShowCloseConfimation}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    id="info-modal"
                >
                    <div id="divcontents">
                        <Modal.Body className="modal-type-2">
                            {/* <div className="colse-mdl" data-dismiss="modal" aria-label="Close" onClick={this.modalClose}>X</div>*/}
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-12 lft">
                                        <h5 className="in-h">{this.state.refund_message}</h5>
                                        <button className="btn btn-danger close-btn"
                                                onClick={this.closeModalRefundConfirm}>Close
                                        </button>
                                    </div>

                                </div>

                            </div>
                        </Modal.Body>
                    </div>
                </Modal>

                <div id="outer-container" className="container-fluid color-orange outer-container-inside-page">
                    <div id="turn-box"></div>
                    <Navigation pathname="transactions"/>
                    <div id="main-content" className="row row_clr dashboard-live-mode">
                        <TopBar pathname="transactions"/>

                        <div className="col-12 transaction-top">
                            <ul className="nav nav-tabs" role="tablist">
                                <li className="nav-item">
                                    <Link to="../transactions" className="nav-link active" id="tt-tab" data-toggle="tab"
                                          role="tab"
                                          aria-controls="tt" aria-selected="true"><span
                                        className="tt">Total Transactions</span>
                                        <span
                                            className="amt">LKR {this.state.lkr_transaction_total}</span>
                                        {
                                            this.state.usd_transaction_total == 0.00 ? '' : <> <span
                                                className="sep">|</span> <span
                                                className="amt">USD {this.state.usd_transaction_total}</span></>
                                        }

                                        {
                                            this.state.gbp_transaction_total == 0.00 ? '' : <> <span
                                                className="sep">|</span> <span
                                                className="amt">GBP {this.state.gbp_transaction_total}</span></>
                                        }
                                        {
                                            this.state.inr_transaction_total == 0.00 ? '' : <> <span
                                                className="sep">|</span> <span
                                                className="amt">INR {this.state.inr_transaction_total}</span></>
                                        }
                                        {
                                            this.state.aud_transaction_total == 0.00 ? '' : <> <span
                                                className="sep">|</span> <span
                                                className="amt">AUD {this.state.aud_transaction_total}</span></>
                                        }

                                    </Link>

                                </li>
                                <li className="nav-item">
                                    <Link to="../transaction-refund" className="nav-link" id="tt-tab"
                                          data-toggle="tab" role="tab"
                                          aria-controls="tr" aria-selected="false"><span
                                        className="tt">Total Refunds</span> <span
                                        className="amt">LKR {this.state.lkr_refund_total}</span>
                                        {
                                            this.state.usd_refund_total == 0.00 ? '' : <> <span className="sep">|</span>
                                                <span
                                                    className="amt">USD {this.state.usd_refund_total}</span></>
                                        }
                                        {
                                            this.state.gbp_refund_total == 0.00 ? '' : <> <span className="sep">|</span>
                                                <span
                                                    className="amt">GBP {this.state.gbp_refund_total}</span></>
                                        }
                                        {
                                            this.state.aud_refund_total == 0.00 ? '' : <> <span className="sep">|</span>
                                                <span
                                                    className="amt">AUD {this.state.aud_refund_total}</span></>
                                        }
                                        {
                                            this.state.inr_refund_total == 0.00 ? '' : <> <span className="sep">|</span>
                                                <span
                                                    className="amt">INR {this.state.inr_refund_total}</span></>
                                        }

                                    </Link>
                                </li>

                                <li className="nav-item">
                                    <Link to="../payment-link-transactions" className="nav-link" id="tt-tab"
                                          data-toggle="tab" role="tab"
                                          aria-controls="tr" aria-selected="false"><span
                                        className="tt"> Payment Link Transactions </span>
                                        <span className="amt">Approved : {this.state.approved_count} | Pending : {this.state.pending_count}</span>
                                    </Link>
                                </li>
                            </ul>
                            <div className="tab-content">
                                <div className="tab-pane fade show active" id="tt" role="tabpanel"
                                     aria-labelledby="tt-tab">
                                    <div className="row row_clr">
                                        <div className="col-12">
                                            <div className="row row_clr tt-ttp">
                                                <form className="col-md-8 col-12 tt-form">
                                                <div className="row row_clr">
                                                        <div className="form-group">
                                                            <label htmlFor="">Transaction ID</label>
                                                            <input type="text" className="form-control"
                                                                   placeholder="Enter Transaction ID"
                                                                   name="order_ref_number"
                                                                   value={this.state.order_ref_number}
                                                                   onChange={this.onChanges}
                                                                   onKeyPress={event => {
                                                                       if (event.key === 'Enter') {
                                                                           this.filterList(event)
                                                                       }
                                                                   }}
                                                            />
                                                        </div>

                                                        <div className="form-group">
                                                            <label htmlFor="">Store Reference</label>
                                                            <input type="text" className="form-control"
                                                                   placeholder="Enter Store Reference"
                                                                   name="store_reference"
                                                                   value={this.state.store_reference}
                                                                   onChange={this.onChanges}
                                                                   onKeyPress={event => {
                                                                       if (event.key === 'Enter') {
                                                                           this.filterList(event)
                                                                       }
                                                                   }}
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="">Status</label>
                                                            <div className="slct">
                                                                <select name="status" id="c1" className="form-control"
                                                                        value={this.state.status}
                                                                        onChange={this.onChanges}>
                                                                    <option value="">Select Status</option>
                                                                    <option value="2">Approved</option>
                                                                    <option value="3">Declined</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="form-group">
                                                            <label>Payment Type</label>
                                                            <div className="slct">
                                                                <select name="payment_type" id="c1"
                                                                        className="form-control"
                                                                        value={this.state.payment_type}
                                                                        onChange={this.onChanges}>
                                                                    <option value=""> Payment Type</option>
                                                                    <option value="Visa">Visa</option>
                                                                    <option value="Master">Master</option>
                                                                    <option value="Nations Trust Bank - AMEX">American
                                                                        Express
                                                                    </option>
                                                                    <option value="SAMPATH VISHWA">Sampath Vishwa
                                                                    </option>
                                                                    <option value="eZ Cash">eZ Cash</option>
                                                                    <option value="mCash">mCash</option>
                                                                    <option value="FriMi (LKR)">FriMi</option>
                                                                    <option value="Genie Visa Master (LKR)">Genie
                                                                    </option>
                                                                    <option value="DFCC Wallet">DFCC Wallet</option>
                                                                    <option value="UPay (LKR)">UPay</option>
                                                                    <option value="other">Other</option>
                                                                </select>
                                                            </div>
                                                            {/* <input type="text" className="form-control"
                                                                   placeholder="Enter Value" name="transaction_value"
                                                                   value={this.state.transaction_value}
                                                                   onChange={this.onChanges}
                                                                   onKeyPress={event => {
                                                                       if (event.key === 'Enter') {
                                                                           this.filterList(event)
                                                                       }
                                                                   }}
                                                            />*/}
                                                        </div>


                                                        <div className="form-group">
                                                            <label htmlFor="">Transaction Currency</label>
                                                            <div className="slct">
                                                                <select name="transaction_currency" id="c1"
                                                                        className="form-control"
                                                                        value={this.state.transaction_currency}
                                                                        onChange={this.onChanges}>
                                                                    <option value="">Select Currency</option>
                                                                    <option value="5">LKR</option>
                                                                    <option value="2">USD</option>
                                                                    <option value="6">GBP</option>
                                                                </select>
                                                            </div>
                                                            {/* <input type="text" className="form-control"
                                                                   placeholder="Enter Value" name="transaction_value"
                                                                   value={this.state.transaction_value}
                                                                   onChange={this.onChanges}
                                                                   onKeyPress={event => {
                                                                       if (event.key === 'Enter') {
                                                                           this.filterList(event)
                                                                       }
                                                                   }}
                                                            />*/}
                                                        </div>


                                                        <div className="form-group">
                                                            <label htmlFor="" style={{marginTop: "10px"}}>Transaction
                                                                Amount</label>
                                                            <input type="text" className="form-control"
                                                                   placeholder="Enter Transaction Amount"
                                                                   name="transaction_value"
                                                                   value={this.state.transaction_value}
                                                                   onChange={this.onChanges}
                                                                   onKeyPress={event => {
                                                                       if (event.key === 'Enter') {
                                                                           this.filterList(event)
                                                                       }
                                                                   }}
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <label style={{marginTop: "10px"}}>Customer Detail</label>
                                                            <input type="text" className="form-control"
                                                                   placeholder="Customer Detail" name="payment_customer"
                                                                   value={this.state.payment_customer}
                                                                   onChange={this.onChanges}
                                                                   onKeyPress={event => {
                                                                       if (event.key === 'Enter') {
                                                                           this.filterList(event)
                                                                       }
                                                                   }}
                                                            />
                                                        </div>

                                                        {/* <div className="form-group">
                                                            <label htmlFor="">Notes</label>
                                                            <input type="text" className="form-control"
                                                                   placeholder="Any Special Notes" />
                                                        </div>*/}
                                                        <div className="form-group">
                                                            <button type="button" className="srch-btn"
                                                                    onClick={this.filterList}
                                                                    style={{marginTop: "15px"}}>Search
                                                            </button>
                                                        </div>
                                                        <div className="form-group">
                                                            <button type="button" className="srch-btn clear-btn"
                                                                    onClick={this.clearSearch}
                                                                    style={{marginTop: "15px"}}>Clear
                                                            </button>
                                                        </div>
                                                    </div>
                                                </form>
                                                <form className="col-md-4 col-12 tr-form">
                                                    <div className="row row_clr">


                                                        <div className="form-group">
                                                            <label htmlFor="">Filters & Reports</label>
                                                            <div className="in table-datepicker">
                                                                <DateRangePicker
                                                                    onEvent={this.handleEvent}
                                                                    onCallback={this.handleCallback}
                                                                    initialSettings={{
                                                                        startDate: moment(this.state.startDate).format('M/DD/YYYY'),
                                                                        endDate: moment(this.state.endDate).format('M/DD/YYYY'),
                                                                        maxDate: moment().format('M/DD/YYYY'),
                                                                        autoApply: true
                                                                    }}

                                                                >
                                                                    <button type="button" className="date-btn"><i
                                                                        className="far fa-calendar-alt"></i> {this.state.dayCount} Days <i
                                                                        className="fas fa-caret-down"
                                                                        aria-hidden="true"></i></button>
                                                                </DateRangePicker>
                                                            </div>
                                                        </div>
                                                        <div className="form-group">

                                                            <Dropdown>
                                                                <Dropdown.Toggle variant="none"
                                                                                 id="settlement-download-dropdown">
                                                                    <i className="fas fa-download"></i>
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu>
                                                                    <CSVLink data={this.state.csvData} style={{
                                                                        textDecoration: "none !important",
                                                                        color: "#000"
                                                                    }} className="dropdown-item"
                                                                             filename={"TransactionReport.csv"}>
                                                                        <i className="fas fa-download"></i> CSV Download
                                                                    </CSVLink>
                                                                    <button type="button"
                                                                            onClick={() => this.exportPDF()}
                                                                            className="dropdown-item"><i
                                                                        className="fas fa-file-pdf"></i>Generate Report
                                                                    </button>

                                                                </Dropdown.Menu>

                                                            </Dropdown>


                                                        </div>

                                                    </div>
                                                </form>
                                            </div>
                                        </div>

                                        <div className="col-12">
                                            <div className='rg-container'>
                                                <table className='transactions-table' summary='Hed'>
                                                    <thead>
                                                    <tr>
                                                        <th className=''>#</th>
                                                        <th className=''>Transaction ID</th>
                                                        <th className=''>Store Reference</th>
                                                        <th className=''>Transaction Amount</th>
                                                        <th className=''>Payment Type</th>
                                                        <th className=''>Date of Transaction</th>
                                                        <th className=''>Payment Status</th>
                                                        <th className=''></th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {transaction_lists}
                                                    </tbody>
                                                </table>
                                                {transaction_lists.length ? (
                                                    <ReactPaginate
                                                        previousLabel={"PREV"}
                                                        nextLabel={"NEXT"}
                                                        breakLabel={"..."}
                                                        breakClassName={"break-me"}
                                                        pageCount={this.state.pageCount}
                                                        marginPagesDisplayed={2}
                                                        pageRangeDisplayed={5}
                                                        onPageChange={this.handlePageClick}
                                                        containerClassName={"pagination"}
                                                        subContainerClassName={"pages pagination"}
                                                        activeClassName={"active"}/>
                                                ) : ('')}
                                            </div>

                                        </div>


                                    </div>
                                </div>

                            </div>
                        </div>

                        <Footer/>

                    </div>
                </div>
            </div>

        );
    }

}


